import React from 'react';
import { EmbedTutorialData } from '@candulabs/core';

import { StyleguideProps } from '../../../types';

export const EmbedTutorial = ({
  attributes: { id: tutorialId, slug, style },
  api,
}: StyleguideProps<EmbedTutorialData>) => {
  return <div style={style}>{api.render.tutorial({ tutorialId, slug })}</div>;
};
