import { LocalStorageCache, StateCache } from '../cache';
import { CanduProviderContextType } from '../../contexts';
import { CanduState, StateResolver, StateResolverType, ResolverOptions } from '../../models';

const registeredStateResolvers = Object.create(null);

// TODO: find a way to make use of the StateResolver types (ie: ChecklistResolver/CardResolver) from models
// We're not using them here because it would require us to spread their options (ie: scope/scopeTrait from the ChecklistResolver) as class properties
// Whereas it's just easier/cleaner if we keep them inside the "options" property like we do here
export abstract class StateResolverInstance<
  S extends CanduState = any,
  R extends StateResolver = any
> {
  // type is assigned when StateResolverInstance.register() is called
  type: StateResolverType;

  state: S;

  options: ResolverOptions<R>;

  cache: StateCache = new LocalStorageCache();

  abstract retrieve(context: CanduProviderContextType, providerId: string): Promise<S>;

  abstract update(
    context: CanduProviderContextType,
    providerId: string,
    update: Partial<S>,
  ): Promise<S>;

  constructor(options?: ResolverOptions<R>) {
    this.options = {
      ...(options || {}),
    } as ResolverOptions<R>;
  }

  static register(
    type: StateResolverType,
    stateResolverClass: new (...args: any[]) => StateResolverInstance,
  ) {
    registeredStateResolvers[type] = stateResolverClass;

    // eslint-disable-next-line
    stateResolverClass.prototype.type = type;
  }

  static fromJSON<R extends StateResolverInstance>({ type, ...options }: any): R | null {
    const Resolver = registeredStateResolvers[type];

    if (!Resolver) {
      return null;
    }

    return new Resolver(options);
  }

  toJSON() {
    return {
      type: this.type,
      ...this.options,
    };
  }
}

export type StateForResolverInstance<R extends StateResolverInstance> = R['state'];
