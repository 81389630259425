import { ApiError, ClientToken, TutorialDocument } from '../models';

import { API_BASE_URL, CDN_BASE_URL, ENDPOINTS } from './constants';
import { VERSION } from './injections';

export const fetchURL = async (url: string, headers?: any) =>
  (
    await fetch(
      url,
      headers && {
        headers,
      },
    )
  ).json();
export const fetchFromApi = (endpoint: string) =>
  fetchURL(`${API_BASE_URL}${endpoint}`, { SDKVersion: VERSION });
export const fetchFromCdn = (endpoint: string) =>
  fetchURL(`${CDN_BASE_URL}${endpoint}`, { SDKVersion: VERSION });

export const getTutorialDocument = (clientToken: ClientToken) => ({
  byId: (tutorialId: number): Promise<TutorialDocument | ApiError> =>
    fetchFromApi(
      `${ENDPOINTS.tutorialDocuments}?token=${clientToken}&finder=latestPublished&tutorialId=${tutorialId}`,
    ),
  bySlug: (slug: string): Promise<TutorialDocument | ApiError> =>
    fetchFromCdn(`/tutorials/${clientToken}/${slug}.json`),
  byContentHash: (contentHashId: string): Promise<TutorialDocument | ApiError> =>
    fetchFromCdn(`/tutorials/${clientToken}/${contentHashId}.json`),
});
