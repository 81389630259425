export type RenderCallback = (element: HTMLElement) => void;

export class NodeManager {
  private state: 'mounted' | 'waiting' = 'waiting';

  private currentElement: HTMLElement | null = null;

  private selector: string;

  private renderToElement: RenderCallback;

  private observer: MutationObserver;

  /**
   * NodeManager calls renderToElement() when the matching selector element
   * appears in the document.
   * @param selector {string} query selector string
   * @param renderToElement render callback
   */
  constructor(selector: string, renderToElement: RenderCallback) {
    this.selector = selector;
    this.renderToElement = renderToElement;
    this.findAndRender();

    this.observer = new MutationObserver((mutations) => {
      switch (this.state) {
        case 'waiting': {
          if (mutations.some((mutation) => !!mutation.addedNodes.length)) {
            // Some nodes were added to the document,
            // check if they're matching the query selector
            this.findAndRender();
          }
          break;
        }
        case 'mounted': {
          // Check if element got unmounted
          mutations.forEach((mutation) => {
            if (!mutation.removedNodes.length) {
              return;
            }
            mutation.removedNodes.forEach((node) => {
              if (node === this.currentElement) {
                this.currentElement = null;
                this.state = 'waiting';
              }
            });
          });
          break;
        }
        default:
          throw new Error(`Unhandled state: ${this.state}`);
      }
    });

    this.observer.observe(document, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });
  }

  private findAndRender() {
    const element = document.querySelector(this.selector) as HTMLElement;
    if (element) {
      this.renderToElement(element);
      this.currentElement = element;
      this.state = 'mounted';
    }
  }

  stopObserving() {
    this.observer.disconnect();
  }
}
