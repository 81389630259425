// Polyfill for non-standard `setImmediate` API which is being used by dataloader
// Previously, this was polyfilled by core-js
import 'setimmediate';
import DataLoader from 'dataloader';

export class ApiDataLoader {
  static loaders: Map<string, DataLoader<any, any>> = new Map();

  static get(key: string, batchLoadFn: any) {
    const existingLoader = ApiDataLoader.loaders.get(key);
    if (existingLoader) {
      return existingLoader;
    }

    const newLoader = new DataLoader(batchLoadFn, { cache: false });

    ApiDataLoader.loaders.set(key, newLoader);
    return newLoader;
  }
}
