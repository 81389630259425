import { Fragment } from 'react';

export const DEFAULT_ALT = '';

export const DEFAULT_ALERT_COLOR = '#EDEDED';

export const DEFAULT_TAB_HEADER_COLOR = '#333745';
export const DEFAULT_TAB_ITEM_COLOR = '#838792';
export const DEFAULT_TAB_ACTIVE_COLOR = '#FEFEFE';
export const DEFAULT_TAB_COLOR = '#FEFEFE';

// Styleguide translation variables
export const tags = ['div', 'a', 'p'];
export const specialComponents = {
  Fragment,
  Document: Fragment,
};

export const colors = {
  primary: '#6310E5',
  secondary: '#59C3C3',
  tertiary: '#4D515D',
  warning: '#FCAD0D',
  danger: '#EE4266',
};
