export class TutorialDependencies {
  private rootDocument: number;

  private adjacencyList: Record<number, number[]> = {};

  constructor(root: number) {
    this.rootDocument = root;
    this.adjacencyList[root] = [];
  }

  get(id: number) {
    return this.adjacencyList[id];
  }

  add(parent: number, child: number) {
    // sanity check, if parent does not exist
    if (!this.adjacencyList[parent]) {
      this.adjacencyList[parent] = [];
    }

    // If child id is not yet in the list
    // Then just add it to the parent
    if (!this.adjacencyList[child]) {
      this.adjacencyList[parent].push(child);
      this.adjacencyList[child] = [];
      return true;
    }

    // Parent already contains child of the same id, so no need to check again
    if (this.adjacencyList[parent].includes(child)) {
      return true;
    }

    const checkForCycles = (id: number) => {
      // We have a cycle if we reach the parent
      if (id === parent) {
        return true;
      }

      const neighbours = this.adjacencyList[id];

      // eslint-disable-next-line no-restricted-syntax
      for (const neighbour of neighbours) {
        // Check each neighbour and its children
        if (checkForCycles(neighbour)) {
          return true;
        }
      }

      return false;
    };

    const hasCycles = checkForCycles(child);

    if (hasCycles) {
      return false;
    }

    this.adjacencyList[parent].push(child);
    return true;
  }
}
