import isString from 'lodash/isString';

import { IsNumericId } from '../utils';

import { ClientToken, UserId } from './Input';

export const isClientToken = (token: any): token is ClientToken =>
  isString(token) && !!token.match(/^[0-9a-zA-Z]*$/) && token.length >= 10;

export const isUserId = (id: any): id is UserId =>
  IsNumericId(id) || (isString(id) && id.trim().length > 0);
