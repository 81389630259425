import React, { FunctionComponent, CSSProperties } from 'react';
import {
  mapNodeToStyleguide,
  fetchHead,
  TutorialDocumentProps,
  EVENT_NAMES,
  createTutorialDocumentContext,
  checkCyclicAndAdd,
  initStateProviders,
  unpackShorthandStyles,
  TrackingIdentifiers,
  TutorialId,
  METRICS,
  Metadata,
  injectToWindow,
} from '@candulabs/core';

import { ERROR_MESSAGES } from '../../constants';
import { useEventing, useTimeToMount } from '../../hooks';
import { useCanduContext } from '../CanduProviderContext';
import { useStyleguide } from '../Styleguide';
import { Tutorial } from '../Tutorial';
import { Portal } from '../Portal';
import { TutorialDocumentContext, useTutorialDocumentContext } from '../TutorialDocumentContext';
import { logger } from '../../utils';
import { usePortalContext } from '../PortalContext';

interface RenderProps {
  tutorialId: TutorialId;
  children: JSX.Element | null;
}

const RenderWithContext = ({ tutorialId, children }: RenderProps) => {
  const { track } = useEventing();
  const tutorialDocumentContext = useTutorialDocumentContext();

  if (tutorialDocumentContext && !checkCyclicAndAdd(tutorialDocumentContext, tutorialId)) {
    logger.error(ERROR_MESSAGES[EVENT_NAMES.CIRCULAR_DEPENDENCY_EMBED_TUTORIAL]);
    track(EVENT_NAMES.CIRCULAR_DEPENDENCY_EMBED_TUTORIAL, { tutorialId });

    return null;
  }

  return (
    <TutorialDocumentContext.Provider
      value={createTutorialDocumentContext(tutorialId, tutorialDocumentContext)}
    >
      {children}
    </TutorialDocumentContext.Provider>
  );
};

export const TutorialDocument: FunctionComponent<
  TutorialDocumentProps & { slug?: Metadata['slug']; contentType: Metadata['contentType'] }
> = ({ tutorialDocument, trackingIdentifiers, slug, contentType }) => {
  const { screen, track } = useEventing();
  const portalContext = usePortalContext();
  const provider = useCanduContext();
  const { getComponent } = useStyleguide();

  useTimeToMount((time) => {
    track(METRICS.TUTORIAL_DOCUMENT_MOUNT_TIME, {
      value: time,
    });
  });

  React.useEffect(() => {
    if (!provider.preview?.client) {
      screen(EVENT_NAMES.TUTORIAL, {
        segmentId: portalContext?.segmentId,
        portalId: portalContext?.portalId,
        tutorialId: tutorialDocument.id,
        versionId: tutorialDocument.versionId,
      });
    }
  }, [tutorialDocument]);

  if (!tutorialDocument.document) {
    return null;
  }

  const { head, stateProviders } = tutorialDocument.document;
  const parentTrackers = trackingIdentifiers?.parentTrackers ?? [];

  const currentTrackingIdentifiers: TrackingIdentifiers = {
    tutorialId: tutorialDocument.id,
    segmentId: portalContext?.segmentId,
    portalId: portalContext?.portalId,
    portalSlug: portalContext?.slug,
    parentTrackers: trackingIdentifiers && [...parentTrackers, trackingIdentifiers],
  };

  if (head) {
    fetchHead(head, provider.clientToken);
  }

  const stateProviderInstances = stateProviders ? initStateProviders(stateProviders, provider) : [];

  const nodesToRender = mapNodeToStyleguide(tutorialDocument.document.rootNodeId, {
    document: tutorialDocument.document,
    trackingIdentifiers: currentTrackingIdentifiers,
    provider,
    renderTutorial: (props) => (
      <Tutorial {...props} trackingIdentifiers={currentTrackingIdentifiers} />
    ),
    renderPortal: (props) => <Portal {...props} />,
    renderNode: (type, props) => {
      try {
        const Component = getComponent(type);
        if (!Component) {
          return null;
        }

        return (
          <Component
            key={props.api.nodeId}
            api={props.api}
            attributes={{
              ...props.attributes,
              style:
                props.attributes.style &&
                unpackShorthandStyles(props.attributes.style as CSSProperties),
            }}
          />
        );
      } catch (e) {
        logger.error(ERROR_MESSAGES[EVENT_NAMES.RENDER_NODE_ERROR], e);
        provider.eventing.error(e);
        return null;
      }
    },
    stateProviderInstances,
  });

  injectToWindow({
    contentType,
    slug: portalContext?.slug || slug,
    clientToken: provider.clientToken,
  });

  return (
    <RenderWithContext tutorialId={tutorialDocument.id}>
      <div data-candu-content={`${contentType}-${portalContext?.slug || slug}`}>
        {nodesToRender}
      </div>
    </RenderWithContext>
  );
};
