import { CanduState } from './CanduState';

export enum CheckListItemStatus {
  NotStared = 'NotStarted',
  InProgress = 'InProgress',
  Done = 'Done',
}

export type CheckListItemModel = CanduState<CheckListItemStatus>;

export enum CheckListScope {
  User = 'User',
  Account = 'Account',
}
