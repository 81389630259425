/**
 * Colors
 */
export enum COLORS {
  PRIMARY = 'primary',
  SECONDARY = 'primary',
  WARNING = 'warning',
  DANGER = 'danger',
  NEUTRAL = 'neutral',
  ACTIVE = 'active',
  INFO = 'info',
}

export const DEFAULT_COLOR = COLORS.PRIMARY;
