import React, { useContext, useState, useEffect } from 'react';
import { PreviewClient } from '@candulabs/core';

import { CanduProviderContext } from '../CanduProviderContext';
import { TutorialContext } from '../TutorialContext';
import { PortalContext } from '../PortalContext';
import { PortalPreviewProps, PortalPreview } from './PortalPreview';
import { TutorialPreviewProps, TutorialPreview } from './TutorialPreview';
import { PreviewContext } from './PreviewContext';

type PreviewWrapperProps = PortalPreviewProps | Omit<TutorialPreviewProps, 'setSegmentId'>;

export const PreviewWrapper = (props: PreviewWrapperProps) => {
  const { children } = props;
  const [showSegmentPicker, setShowSegmentPicker] = useState(false);
  const [segmentId, setSegmentId] = useState<string | null>(null);
  const providerContext = useContext(CanduProviderContext);
  const tutorialContext = useContext(TutorialContext);
  const portalContext = useContext(PortalContext);
  const previewContext = useContext(PreviewContext);

  const [previewClient, setPreviewClient] = useState<PreviewClient | null>(null);

  useEffect(() => {
    const unsubscribe = providerContext.preview.onClientUpdate(({ client }) => {
      setPreviewClient(client);
    });

    return () => unsubscribe();
  }, []);

  // reset segment picker when switching tutorials
  useEffect(() => {
    if (previewClient && !tutorialContext && !portalContext) {
      setShowSegmentPicker(false);
    }
  }, [props.currentTutorialId]);

  // if preview is of type portal and tutorial context exists,
  // that means we are and embedded portal and we need to show segment picker
  // in that tutorials preview by passing that information via context
  if (props.type === 'portal' && tutorialContext && !previewContext.showSegmentPicker) {
    previewContext.setShowSegmentPicker(true);
  }

  if (previewClient && !tutorialContext && !portalContext) {
    return (
      <PreviewContext.Provider
        value={{
          showSegmentPicker,
          setShowSegmentPicker,
          segmentId,
        }}
      >
        {props.type === 'tutorial' ? (
          <TutorialPreview
            {...props}
            setSegmentId={setSegmentId}
            forcePreview={
              // for now in Tutorials we only support tutorial force previews
              previewClient.options?.forcePreview?.type === 'Tutorial'
                ? previewClient.options?.forcePreview
                : undefined
            }
          />
        ) : (
          <PortalPreview {...props} forcePreview={previewClient.options?.forcePreview} />
        )}
      </PreviewContext.Provider>
    );
  }

  return <>{children}</>;
};
