// keep a track of all the components that exist in a styleguide.
// NOTE! this must be kept up to date when updating the styleguide.
export const defaultStyleguide = {
  Alert: true,
  B: true,
  Badge: true,
  Button: true,
  Card: true,
  Code: true,
  Document: true,
  Em: true,
  EmbedPortal: true,
  EmbedTutorial: true,
  Flex: true,
  FlexItem: true,
  H1: true,
  H2: true,
  H3: true,
  H4: true,
  H5: true,
  H6: true,
  Image: true,
  InlineCode: true,
  Link: true,
  List: true,
  ListItem: true,
  P: true,
  RichText: true,
  Spacing: true,
  Text: true,
  U: true,
  View: true,
  Player: true,
};
