import {
  SingletonAPI,
  createSingleton,
  ProviderProps,
  CanduProviderContextType,
  PortalProps,
  SingletonRenderProp,
  TutorialProps,
  ContentProps,
} from '@candulabs/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Styleguide } from '../../types';

import { SDK_INFO } from '../../constants';
import { CanduProvider } from '../CanduProvider';
import { Portal } from '../Portal';
import { Tutorial } from '../Tutorial';
import { Content } from '../Content';
import { defaultProviderContext } from '../CanduProviderContext';

interface Props extends ProviderProps {
  styleguide?: Partial<Styleguide>;
}

const INIT_ERROR = 'Candu is not initialized, call init(..) first';
const INVALID_ELEMENT_ERROR = 'element is not provided';

class CanduReactSingletonAPI extends SingletonAPI {
  name = SDK_INFO.name;

  version = SDK_INFO.version;

  providerProps: CanduProviderContextType;

  init(props: Props) {
    this.providerProps = { ...defaultProviderContext, ...props };
  }

  renderTutorial(props: SingletonRenderProp<TutorialProps>): () => void {
    if (!this.providerProps) {
      throw new Error(INIT_ERROR);
    }
    if (!props?.element) {
      throw new Error(INVALID_ELEMENT_ERROR);
    }

    const { element, ...tutorialProps } = props;

    ReactDOM.render(
      <CanduProvider {...this.providerProps}>
        <Tutorial {...tutorialProps} />
      </CanduProvider>,
      element,
    );

    return () => {
      ReactDOM.unmountComponentAtNode(element);
    };
  }

  renderPortal(props: SingletonRenderProp<PortalProps>): () => void {
    if (!this.providerProps) {
      throw new Error(INIT_ERROR);
    }
    if (!props?.element) {
      throw new Error(INVALID_ELEMENT_ERROR);
    }

    const { element, ...portalProps } = props;

    ReactDOM.render(
      <CanduProvider {...this.providerProps}>
        <Portal {...portalProps} />
      </CanduProvider>,
      element,
    );

    return () => {
      ReactDOM.unmountComponentAtNode(element);
    };
  }

  renderContent(props: SingletonRenderProp<ContentProps>): () => void {
    if (!this.providerProps) {
      throw new Error(INIT_ERROR);
    }
    if (!props?.element) {
      throw new Error(INVALID_ELEMENT_ERROR);
    }

    const { element, ...contentProps } = props;

    ReactDOM.render(
      <CanduProvider {...this.providerProps}>
        <Content {...contentProps} />
      </CanduProvider>,
      element,
    );

    return () => {
      ReactDOM.unmountComponentAtNode(element);
    };
  }

  getProvider(): CanduProviderContextType {
    if (!this.providerProps) {
      throw new Error(INIT_ERROR);
    }

    /**
     * This getProvider is used by the SingletonAPI and
     * expects us to return the provider props in order
     * to behave correctly.
     */
    return this.providerProps;
  }
}

export const Candu = createSingleton(CanduReactSingletonAPI);
