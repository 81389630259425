import { CSSProperties } from 'react';

const containerStyle = (accentColor): CSSProperties => ({
  border: `4px solid ${accentColor}`,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  boxSizing: 'border-box',
});

const toolBarStyle = (accentColor): CSSProperties => ({
  width: '100%',
  height: '42px',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  background: accentColor,
  padding: '8px 16px 12px 16px',
  justifyContent: 'space-between',
  fontFamily: 'sofia-pro, sans-serif',
  borderTop: `2px solid ${accentColor}`,
});

const wrapperStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const contentWrapper: CSSProperties = {
  padding: '16px',
};

const forcePreviewLabel: CSSProperties = {
  fontSize: '18px',
  marginRight: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const editorButton: CSSProperties = {
  width: '140px',
  height: '30px',
  display: 'flex',
  fontSize: '16px',
  lineHeight: '1em',
  cursor: 'pointer',
  fontWeight: 'bold',
  fontStyle: 'normal',
  marginRight: '16px',
  borderRadius: '4px',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid white',
  fontFamily: 'sofia-pro, sans-serif',
};

const contentState: CSSProperties = {
  color: 'white',
  width: '150px',
  display: 'flex',
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '1em',
  padding: '5px 22px',
  alignItems: 'center',
  borderRadius: '24px',
  justifyContent: 'center',
  fontFamily: 'sofia-pro, sans-serif',
};

const pending: CSSProperties = {
  ...contentState,
  background: '#FCAD0D',
};

const published: CSSProperties = {
  ...contentState,
  background: '#59C3C3',
};

const draft: CSSProperties = {
  ...contentState,
  color: '#505562',
  background: '#D4D8E4',
};

export const styles = {
  wrapperStyle,
  containerStyle,
  toolBarStyle,
  contentWrapper,
  forcePreviewLabel,
  editorButton,
  pending,
  published,
  draft,
};
