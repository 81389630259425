import { Styleguide } from '../../types';

import {
  Alert,
  B,
  Badge,
  Button,
  Card,
  Code,
  Document,
  Em,
  EmbedPortal,
  EmbedTutorial,
  Flex,
  FlexItem,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Image,
  InlineCode,
  Link,
  List,
  ListItem,
  P,
  Spacing,
  Text,
  U,
  View,
  Player,
  CheckList,
  CheckListGroup,
  CheckListItem,
} from './components';

// we export it to simplify unit testing
export const defaultStyleguide: Styleguide = {
  Alert,
  B,
  Badge,
  Button,
  Card,
  Code,
  Document,
  Em,
  EmbedPortal,
  EmbedTutorial,
  Flex,
  FlexItem,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Image,
  InlineCode,
  Link,
  List,
  ListItem,
  P,
  RichText: View,
  Spacing,
  Text,
  U,
  View,
  Player,
  CheckList,
  CheckListGroup,
  CheckListItem,
};
// TODO! make sure that this default styleguide implements all the components in the core default styleguide
// DONT MERGE WITHOUT IT
