// eslint-disable-next-line no-console
export const logError = (...args) => console.error(...args);

// eslint-disable-next-line no-console
export const logWarning = (...args) => console.warn(...args);

export const logger = {
  error: logError,
  warning: logWarning,
};
