import React from 'react';

import { toCssSelector } from '@candulabs/core';

import { StyleguideProps } from '../../../types';

enum ListType {
  Ordered = 'OL',
  Unordered = 'UL',
}

/**
 * A List.
 *
 * Equivalent of either `<ol />` or <ul /> in HTML.
 */
export const List = ({ attributes, api }: StyleguideProps) => {
  const { nodeId, document } = api;
  const { data } = document.nodes[nodeId] || {};

  // @ts-ignore
  if (data && data.custom && data.custom.variant === ListType.Unordered) {
    return <ul {...attributes} />;
  }
  return <ol {...attributes} />;
};

/**
 * A ListItem.
 *
 * Equivalent of `<li />` in HTML.
 * We don't recommend overriding this component unless you have a specific use-case.
 */
export const ListItem = ({ attributes }: StyleguideProps) => {
  // temporary hack to use P class to style list item
  const className = toCssSelector('typography', {
    variant: { variant: 'P' },
    output: 'html',
  });
  return <li {...attributes} className={className} />;
};
