import { defaultStyleguide } from '../styleguide/defaultStyleguide';
// given a styleguide, it takes all those events that are
export const trackStyleguideProperties = (styleguide: Record<string, any>): object => {
  const styleguideProperties = {};
  Object.keys(styleguide).forEach((key) => {
    if (defaultStyleguide[key]) {
      styleguideProperties[`styleguide.${key}`] = styleguide[key].displayName || true;
    }
  });
  return styleguideProperties;
};
