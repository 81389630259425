import React from 'react';

interface ArrowProps {
  direction: 'up' | 'down';
}

export const Arrow = ({ direction }: ArrowProps) => {
  return (
    <svg
      style={{
        transition: 'all .35s ease-out 0s',
        transform: `rotateZ(${direction === 'up' ? '180deg' : '0deg'})`,
      }}
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6.74488L3.09046 0.555221C2.39741 -0.170679 1.25783 -0.186906 0.545126 0.518977C-0.167576 1.22486 -0.183508 2.38555 0.509541 3.11145L7.37034 10.2975L7.37166 10.2988C8.26633 11.2347 9.73727 11.2336 10.6306 10.2965L17.4905 3.11145C18.1835 2.38555 18.1676 1.22486 17.4549 0.518977C16.7422 -0.186906 15.6026 -0.170679 14.9095 0.555221L9 6.74488Z"
        fill="#D4D8E4"
      />
    </svg>
  );
};
