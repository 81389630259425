import * as injections from './injections';

export const { API_BASE_URL } = injections;
export const { CDN_BASE_URL } = injections;

export const ENDPOINTS = {
  checkListItems: '/api/checklistItems',
  cards: '/api/cards',
  segmentMembership: '/api/segmentMemberships',
  segmentsDefinition: '/api/segmentsDefinition',
  tutorialMetadata: '/api/tutorialMetadata',
  tutorialDocuments: '/api/tutorialDocumentsSdk',
};
