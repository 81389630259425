export enum TRIGGERS {
  ON_CLICK = 'OnClick',
}

export enum CATEGORIES {
  NATIVE = 'Native',
  PENDO = 'Pendo',
}

export enum HANDLER_TYPES {
  NAVIGATE = 'Navigate',
  CHANGE_TUTORIAL = 'ChangeTutorial',
  OPEN_GUIDE = 'OpenGuide',
  DISMISSED_CARD = 'DismissedCard',
}

export interface NavigateAction {
  category: CATEGORIES.NATIVE;
  trigger: TRIGGERS.ON_CLICK;
  handler: {
    type: HANDLER_TYPES.NAVIGATE;
    href: string;
    target?: 'Blank' | 'Self' | 'Top' | 'Parent';
  };
  eventName: string;
}

export interface TutorialChangeAction {
  category: CATEGORIES.NATIVE;
  trigger: TRIGGERS.ON_CLICK;
  handler: {
    type: HANDLER_TYPES.CHANGE_TUTORIAL;
    tutorialId: string;
  };
  eventName: string;
}

export interface PendoOpenGuideAction {
  category: CATEGORIES.PENDO;
  trigger: TRIGGERS.ON_CLICK;
  handler: {
    type: HANDLER_TYPES.OPEN_GUIDE;
    guideId: string;
  };
  eventName: string;
}

export interface DismissedCardAction {
  category: CATEGORIES.NATIVE;
  trigger: TRIGGERS.ON_CLICK;
  handler: {
    type: HANDLER_TYPES.DISMISSED_CARD;
    cardId: string;
  };
  tutorialId: string;
  eventName: string;
}

export type Action =
  | NavigateAction
  | TutorialChangeAction
  | PendoOpenGuideAction
  | DismissedCardAction;
