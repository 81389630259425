import React, { useContext, useEffect, useState } from 'react';

import {
  TutorialId,
  TutorialMetadata,
  TutorialDocument,
  DEFAULT_SEGMENT_ID,
  RESOURCES,
  ForcePreviewTutorial,
  ForcePreviewPortal,
  getContentState,
} from '@candulabs/core';

import { useFetch } from '../../hooks';
import { CanduProviderContext } from '../CanduProviderContext';
import { Dropdown } from './Dropdown';
import { TutorialIcon } from './TutorialIcon';
import { styles } from './PreviewWrapperStyles';
import { PreviewContext } from './PreviewContext';
import { useTutorialSocket } from './useTutorialSocket';
import { SegmentIcon } from './SegmentIcon';
import { ContentState } from './ContentState';
import { CanduLogo } from './CanduLogo';
import { API_BASE_URL } from '../../constants';

export interface TutorialPreviewProps {
  type: 'tutorial';
  currentTutorialId?: TutorialId;
  onTutorialChange: (tutorialDocument: TutorialDocument | null) => void;
  setSegmentId: (segmentId: string | null) => void;
  children: JSX.Element;
  forcePreview?: ForcePreviewTutorial | ForcePreviewPortal;
}

type SegmentDefinition = {
  id: string;
  name: string;
};

export const TutorialPreview = ({
  children,
  currentTutorialId,
  onTutorialChange,
  setSegmentId,
  forcePreview,
}: TutorialPreviewProps) => {
  const providerContext = useContext(CanduProviderContext);
  const previewContext = useContext(PreviewContext);
  const { clientToken } = providerContext;

  const [segmentList, setSegmentList] = useState<SegmentDefinition[]>([]);

  const accentColor = '#6310E5';
  const { result: tutorials } = useFetch<TutorialMetadata[]>(() =>
    RESOURCES.tutorialMetadata(providerContext.clientToken),
  );

  // listen to socket updates
  useTutorialSocket({
    tutorialId: currentTutorialId,
    onTutorialSocket: (document) => onTutorialChange(document),
  });

  // reset tutorial on unmount
  useEffect(() => {
    return () => onTutorialChange(null);
  }, []);

  // TODO typescript here
  const loadSavedTutorial = (tutorial) => {
    const {
      value: { id: tutorialId },
    } = tutorial;
    // load latest saved tutorial by id
    const promise = RESOURCES.tutorialDocumentLatestSaved(clientToken, tutorialId);
    if (!promise) {
      return;
    }

    promise.then((result: TutorialDocument) => {
      onTutorialChange(result);
    });
  };

  const changeCurrentSegment = (segment) => {
    setSegmentId(segment.value.id);
  };

  // on mount we load current tutorial in latest saved version
  useEffect(() => {
    if (forcePreview) {
      if (forcePreview.type === 'Tutorial') {
        loadSavedTutorial({ value: { id: forcePreview.props.tutorialId } });
      } else {
        // TODO do we need to support force portal preview ?
        loadSavedTutorial({ value: { id: currentTutorialId } });
      }
    } else {
      loadSavedTutorial({ value: { id: currentTutorialId } });
    }
  }, []);

  // load segment list if prop is set and no force preview
  useEffect(() => {
    if (!previewContext.showSegmentPicker || forcePreview) {
      return;
    }

    // get list of segments and map over portal asset
    const promise = RESOURCES.segmentDefinition(providerContext.clientToken);

    if (!promise) {
      return;
    }

    promise.then((segmentDefinitions: SegmentDefinition[]) => {
      const segmentNames: SegmentDefinition[] = [
        {
          id: DEFAULT_SEGMENT_ID,
          name: DEFAULT_SEGMENT_ID,
        },
        ...segmentDefinitions.map(({ id, name }) => ({ id, name })),
      ];

      setSegmentList(segmentNames);
    });
  }, [previewContext.showSegmentPicker]);

  const handleOpenEditor = () => {
    const slug = forcePreview?.currentApplication.slug;
    window.open(`${API_BASE_URL}/workspaces/${slug}/tutorials/${currentTutorialId}/content`);
  };

  if (!tutorials) {
    return null;
  }

  const currentTutorial = tutorials.find(({ id }) => id === currentTutorialId);

  return (
    <div style={styles.containerStyle(accentColor)}>
      <div style={styles.toolBarStyle(accentColor)}>
        {!forcePreview && (
          <Dropdown
            icon={TutorialIcon()}
            items={tutorials.map(({ id, slug, name }) => ({
              value: { id, slug },
              id,
              selected: currentTutorialId === id,
              label: name,
            }))}
            onChange={loadSavedTutorial}
          />
        )}
        {!forcePreview && previewContext.showSegmentPicker && (
          <Dropdown
            icon={SegmentIcon()}
            items={segmentList.map(({ id, name }) => ({
              value: { id },
              id,
              selected: previewContext.segmentId === id,
              label: name,
            }))}
            onChange={changeCurrentSegment}
          />
        )}
        {forcePreview && (
          <React.Fragment>
            <div style={styles.wrapperStyle}>
              <CanduLogo style={{ marginRight: '14px' }} />
              <span style={styles.forcePreviewLabel}>
                Previewing tutorial:{' '}
                {forcePreview.type === 'Tutorial'
                  ? tutorials.find((item) => item.id === forcePreview.props.tutorialId)?.name ??
                    forcePreview.props.tutorialId
                  : forcePreview.props.slug}
              </span>
            </div>
            <div style={styles.wrapperStyle}>
              <div
                onClick={handleOpenEditor}
                onKeyPress={handleOpenEditor}
                role="button"
                tabIndex={0}
                style={styles.editorButton}
              >
                Open in Editor
              </div>
              {currentTutorial && <ContentState state={getContentState(currentTutorial)} />}
            </div>
          </React.Fragment>
        )}
      </div>

      <div style={styles.contentWrapper}>{children}</div>
    </div>
  );
};
