import memoize from 'lodash/memoize';

import { CanduNodeContextType } from '../contexts';
import { StyleguideComponents } from './types';

export const mergeStyleguidesFromContext = memoize(
  (context: CanduNodeContextType, overrideStyleguide?: Partial<StyleguideComponents>) => {
    const { provider, portal, tutorial } = context;

    const providerStyleguide = provider.styleguide;

    let portalStyleguide;
    let tutorialStyleguide;

    if (tutorial) {
      tutorialStyleguide = tutorial.styleguide;
    }

    if (portal) {
      portalStyleguide = portal.styleguide;
    }

    return {
      ...(providerStyleguide || {}),
      ...(portalStyleguide || {}),
      ...(tutorialStyleguide || {}),
      ...(overrideStyleguide || {}),
    };
  },
);
