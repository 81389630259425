import { stylguideClassNameMap, CSSNodeTypes } from './constants';
import { StyleguideCSSNode } from './types';

type Config = {
  className?: string;
  variant?: Record<string, string> | undefined;
  selector?: string;

  // Specifies if we want the HTML className or the CSS selector
  // If css => ".candu-button.color-primary"
  // If html => "candu-button color-primary"
  output?: 'css' | 'html';
};

export const toCssSelector = (componentName: string, config?: Config): string => {
  const { className, variant, selector, output } = {
    className: 'root',
    variant: undefined,
    selector: '',
    output: 'css',
    ...(config || {}),
  };
  const componentClassName = `candu-${componentName}`;

  if (className === 'global') {
    if (output === 'html') {
      return '';
    }

    return ':root';
  }

  let childClassName: string | null = null;
  let variantClassNames: string[] = [];

  const classNames = stylguideClassNameMap[componentName] || {};

  if (variant && classNames.variants) {
    const classVariants = classNames.variants;

    variantClassNames = Object.keys(variant).reduce((accum, key) => {
      const value = variant[key];
      const getVariantClassName = classVariants[key];

      accum.push(getVariantClassName ? getVariantClassName(value) : `${key}-${value}`);
      return accum;
    }, [] as string[]);
  }

  if (className !== 'root' && classNames.childClassNames && classNames.childClassNames[className]) {
    childClassName = `candu-${componentName}--${classNames.childClassNames[className]}`;
  }

  // Returns classnames as a CSS selector
  if (output === 'css') {
    return (
      [componentClassName, childClassName, selector].reduce((accum, str, i) => {
        if (!str || str === '') {
          return accum;
        }

        // Component class name
        if (i === 0) {
          // Returns .candu-button.variant-1.variant-2
          return `.${componentClassName}${variantClassNames.map((c) => `.${c}`).join('')}`;
        }

        // Child class name
        if (i === 1) {
          return `${accum} .${str}`;
        }

        // Selector
        return `${accum}${str}`;
      }, '') || ''
    );
  }

  // If output=html, return as HTML class names
  if (className === 'root') {
    // Returns candu-button variant-1 variant-2
    return [componentClassName, ...variantClassNames].filter((str) => !!str).join(' ') || '';
  }

  return childClassName || '';
};

export const cssInputObjToString = (obj: Record<string, any>): string => {
  return Object.keys(obj).reduce((accum, key) => `${accum}${key}: ${obj[key]};`, '');
};

export const cssInputStringToObj = (input: string): Record<string, string> => {
  return input.split(';').reduce((accum, definition) => {
    const [propName, propValue] = definition.split(':');

    if (!propName || !propValue) {
      return accum;
    }

    return {
      ...accum,
      [propName.trim()]: propValue.trim(),
    };
  }, {} as Record<string, string>);
};

// Transform a kv pair of CSS styles into a declaration StyleguideCSSNode
// Eg: {display: block} ==> {type: "decl", prop: "display", value: "block"}
export const toStyleguideDecl = (
  cssObject: Record<string, string>,
): StyleguideCSSNode<CSSNodeTypes.Decl>[] => {
  return Object.keys(cssObject).reduce((accum, key) => {
    return [
      ...accum,
      {
        type: CSSNodeTypes.Decl,
        prop: key,
        value: cssObject[key],
      },
    ];
  }, [] as StyleguideCSSNode<CSSNodeTypes.Decl>[]);
};
