import React, { FunctionComponent } from 'react';

import { AlertData } from '@candulabs/core';

import { DEFAULT_ALERT_COLOR, colors } from '../constants';
import { StyleguideProps } from '../../../types';

const defaultStyles = {
  borderRadius: '4px',
  padding: '8px',
};

// Returns some arbitrary colors for now
const getColorForIntent = (intent) => {
  switch (intent) {
    case 'success': {
      return colors.primary;
    }
    case 'active': {
      return colors.secondary;
    }
    case 'warning': {
      return colors.warning;
    }
    case 'danger': {
      return colors.danger;
    }
    default: {
      return colors.tertiary;
    }
  }
};

/**
 * Alert blocks are used to catch User attention.
 *
 * Similar to a Notification component in Bootstrap.
 */
export const Alert: FunctionComponent<StyleguideProps<AlertData>> = ({
  attributes,
}: StyleguideProps<AlertData>) => {
  const { intent, title, description } = attributes;
  return (
    <div
      {...attributes}
      style={{
        ...defaultStyles,
        ...attributes.style,
        color: '#FFF',
        backgroundColor: getColorForIntent(intent) || DEFAULT_ALERT_COLOR,
      }}
    >
      {title}
      <div>
        <small>{description}</small>
      </div>
    </div>
  );
};
