import { StateForResolverInstance, StateResolverInstance } from './resolvers';
import { logger, Observer, ObserverSubscriber } from '../utils';
import { ERROR_MESSAGES, EVENT_NAMES } from '../internalEventing';
import { CanduProviderContextType } from '../contexts';

export class StateProviderInstance<R extends StateResolverInstance = any> {
  private state: StateForResolverInstance<R>;

  readonly resolver: R;

  private observer = new Observer<StateForResolverInstance<R>>();

  private context: CanduProviderContextType;

  providerId: string;

  constructor(context: CanduProviderContextType, providerId: string, resolver: R) {
    this.context = context;
    this.providerId = providerId;
    this.resolver = resolver;

    // Try to load cached state, null if not found
    this.state = this.resolver.cache.load(this.providerId);

    // Trigger fetch
    this.resolver
      .retrieve(context, providerId)
      .then((state) => this.updateState(state))
      .catch((error) => {
        logger.error(ERROR_MESSAGES[EVENT_NAMES.STATE_FETCH_FAILED], error.message);
        this.context.eventing.track(ERROR_MESSAGES[EVENT_NAMES.STATE_FETCH_FAILED], {
          errorMessage: error.message,
        });
      });
  }

  mutate(newState: Partial<StateForResolverInstance<R>>) {
    const oldState = this.state;

    // Optimistically update state
    this.updateState({ ...oldState, ...newState });
    this.resolver
      .update(this.context, this.providerId, newState)
      .then((state) => this.updateState(state))
      .catch((error) => {
        logger.error(ERROR_MESSAGES[EVENT_NAMES.STATE_UPDATE_FAILED], error.message);
        this.context.eventing.track(ERROR_MESSAGES[EVENT_NAMES.STATE_UPDATE_FAILED], {
          errorMessage: error.message,
        });
        // Roll back to old state
        if (oldState) this.updateState(oldState);
      });
  }

  subscribe(subscriber: ObserverSubscriber<Observer<StateForResolverInstance<R>>>) {
    const unsubscriber = this.observer.subscribe(subscriber);

    if (this.state) {
      subscriber(this.state);
    }

    return unsubscriber;
  }

  getResolvedState(): StateForResolverInstance<R> | null {
    return this.state;
  }

  private updateState(state: StateForResolverInstance<R>) {
    this.state = state;
    this.resolver.cache.save(this.providerId, state);
    this.observer.notify(state);
  }
}

export type StateProviderInstances = StateProviderInstance[];
