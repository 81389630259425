import React, { FunctionComponent } from 'react';
import { BadgeData, toCssSelector } from '@candulabs/core';

import { StyleguideProps } from '../../../types';

export const Badge: FunctionComponent<StyleguideProps<BadgeData>> = ({
  attributes: { color, outline, ...attributes },
}: StyleguideProps<BadgeData>) => {
  const className = toCssSelector('badge', {
    variant: {
      color,
      outline,
    },
    output: 'html',
  });
  return <span {...attributes} className={className} />;
};
