import * as injections from './injections';
import { EventType, PackageInfo } from './types/Event';

export const { API_BASE_URL } = injections;

export const EVENT_API = '/api/events';

export const EVENT_URL = `${API_BASE_URL}${EVENT_API}`;

export const EVENT_TYPES = EventType;

export const clientLibrary: PackageInfo = {
  name: 'JsClient',
  version: injections.VERSION,
};

/**
 * Storage keys used for
 */
export const ANONYMOUS_ID_STORAGE_KEY = 'candu:anonymous_id';

export const SENTRY_DSN =
  'https://bf02871777614ec786d84577da9afc6c@o238672.ingest.sentry.io/5589365';

/**
 * Window Pollyfill object
 */
export const windowObject = {
  location: {
    pathname: '',
    href: '',
    search: '',
  },
  screen: {
    height: 0,
    width: 0,
  },
  Intl: {
    DateTimeFormat: () => ({
      resolvedOptions: () => ({ timeZone: '' }),
    }),
  },
};
