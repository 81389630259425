import React from 'react';
import { HtmlComponent } from './HtmlComponent';

/**
 * NOTE!
 * While we could simplify this components,
 * we need to render them for reactdoc gen
 * to pick up the comments.
 */

/**
 * A H1 Header
 *
 * Equivalent of `<h1>` element in HTML.
 */
export const H1 = (props) => {
  const Component = HtmlComponent('h1');
  return <Component {...props} />;
};

/**
 * A H2 Header
 *
 * Equivalent of `<h2>` element in HTML.
 */
export const H2 = (props) => {
  const Component = HtmlComponent('h2');
  return <Component {...props} />;
};

/**
 * A H3 Header
 *
 * Equivalent of `<h3>` element in HTML.
 */
export const H3 = (props) => {
  const Component = HtmlComponent('h3');
  return <Component {...props} />;
};

/**
 * A H4 Header
 *
 * Equivalent of `<h4>` element in HTML.
 */
export const H4 = (props) => {
  const Component = HtmlComponent('h4');
  return <Component {...props} />;
};

/**
 * A H5 Header
 *
 * Equivalent of `<h4>` element in HTML.
 */
export const H5 = (props) => {
  const Component = HtmlComponent('h5');
  return <Component {...props} />;
};

/**
 * A H6 Header
 *
 * Equivalent of `<h6>` element in HTML.
 */
export const H6 = (props) => {
  const Component = HtmlComponent('h6');
  return <Component {...props} />;
};

/**
 * Make your text bold.
 *
 * Equivalent of `<b>` element in HTML.
 */
export const B = (props) => {
  const Component = HtmlComponent('b');
  return <Component {...props} />;
};

/**
 * Make your text Italic
 *
 * Equivalent of `<em>` element in HTML.
 */
export const Em = (props) => {
  const Component = HtmlComponent('em');
  return <Component {...props} />;
};

/**
 * Write some inline code.
 *
 * Equivalent of `<code>` element in HTML.
 */
export const InlineCode = (props) => {
  const Component = HtmlComponent('code');
  return <Component {...props} />;
};

/**
 * Paragraph text
 *
 * Equivalent of `<p>` element in HTML.
 */
export const P = (props) => {
  const Component = HtmlComponent('p');
  return <Component {...props} />;
};

/**
 * Make your text underline.
 *
 * Equivalent of `<u>` element in HTML.
 */
export const U = (props) => {
  const Component = HtmlComponent('u');
  return <Component {...props} />;
};
