import { getAnonymousId } from '@candulabs/eventing';
import invariant from 'invariant';
import isObject from 'lodash/isObject';

import {
  CanduClientOptions,
  ClientToken,
  ContentHashId,
  isClientToken,
  isUserId,
  PortalSlug,
  TrackingIdentifiers,
  TutorialDocument as TutorialDocumentType,
  TutorialId,
  TutorialSlug,
  UserId,
} from '../models';
import { Eventing } from '../../../candu-eventing/dist';

const isValidStyleguideProps = (styleguide) =>
  invariant(
    !styleguide || isObject(styleguide),
    '(Candu) styleguide must be undefined or an object',
  );

export interface ProviderProps {
  /** The client token associated with the application we want to render */
  clientToken: ClientToken;
  /** The userId of the customer you want to track. If not set an anonymous id will be used instead */
  userId?: UserId;
  /** Any `traits` that you want to track for each user */
  traits?: Record<string, unknown>;
  /** A generic version of a styleguide */
  styleguide?: Record<string, unknown>;
  /** @hidden A customer can optionally pass an eventing object to customize the way they pass events */
  eventing?: Eventing;
  /** @hidden A set of advanced options. Check the documentation for more information. */
  options?: CanduClientOptions;
}

export const providerValidation = <T extends ProviderProps>({
  clientToken,
  userId,
  traits,
  options,
  styleguide,
  ...otherProps
}: T) => {
  invariant(isClientToken(clientToken), '(Candu) you must provide a valid clientToken');
  invariant(!userId || isUserId(userId), '(Candu) you must provide a valid userId');

  isValidStyleguideProps(styleguide);
  invariant(!traits || isObject(traits), '(Candu) traits must be undefined or an object');
  // TODO: add the right shape to options
  invariant(!options || isObject(options), '(Candu) options must be undefined or an object');

  return {
    ...otherProps,
    clientToken,
    traits: traits || {},
    options: options || {},
    styleguide: styleguide || {},
    userId: userId || getAnonymousId(),
  };
};

export interface TutorialProps {
  /** Render a Tutorial by providing a slug. */
  slug?: TutorialSlug;

  /** Render a styleguide specifc to the Tutorial. */
  styleguide?: object;

  /** @hidden  Render a specific commit from our CDN. Used by portals to render a tutorial. */
  contentHashId?: ContentHashId;

  /** @hidden Render a specific tutorial id from the API. Useful for testing purposes. */
  tutorialId?: TutorialId;

  /** @hidden Render a specific tutorial document, useful for debugging */
  tutorialDocument?: TutorialDocumentType;

  /** @hidden specifies whether the render was triggered by a client */
  renderedByClient?: boolean;

  trackingIdentifiers?: TrackingIdentifiers;
}

export const tutorialValidation = <T extends TutorialProps>({
  slug,
  styleguide,
  contentHashId,
  tutorialId,
  tutorialDocument,
  renderedByClient,
  ...otherProps
}: T) => {
  isValidStyleguideProps(styleguide);

  return {
    ...otherProps,
    slug,
    styleguide: styleguide || {},
    contentHashId,
    tutorialId,
    tutorialDocument,
    renderedByClient,
  };
};

export interface PortalProps {
  slug: PortalSlug;
  styleguide?: object;

  /** @hidden specifies whether the render was triggered by a client */
  renderedByClient?: boolean;
}

export interface ContentProps {
  slug: PortalSlug;
}

export const portalValidation = <T extends PortalProps>({ slug, styleguide }: T) => {
  isValidStyleguideProps(styleguide);

  return {
    slug,
    styleguide: styleguide || {},
  };
};

export interface TutorialDocumentProps {
  tutorialDocument: TutorialDocumentType;
  trackingIdentifiers?: TrackingIdentifiers;
}
