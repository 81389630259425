import {
  Action,
  EditorNode,
  HANDLER_TYPES,
  TrackingIdentifiers,
  TRIGGERS,
  Document,
} from '../models';

import { CanduProviderContextType } from '../contexts';
import { EVENT_NAMES } from '../internalEventing';
import { logError } from '../utils';

const getEventHandler = (action: Action) => {
  switch (action.trigger) {
    case TRIGGERS.ON_CLICK:
      return 'onClick';

    default:
      return 'onClick';
  }
};

const getActionHandler = (
  action: Action,
  tracker: (action: Action, trackingIdentifiers?: TrackingIdentifiers) => any,
  trackingIdentifiers: TrackingIdentifiers,
) => {
  switch (action.handler.type) {
    case HANDLER_TYPES.NAVIGATE:
      return {
        [getEventHandler(action)]: (e) => {
          e.stopPropagation();
          // @ts-ignore
          const { href, target } = action.handler;

          const { parentTrackers, ...trackIdentifierData } = trackingIdentifiers || ({} as any);

          if (parentTrackers) {
            parentTrackers.forEach((identifiers) => tracker(action, identifiers));
          }

          tracker(action, trackIdentifierData);

          if (target === 'Blank') {
            window.open(href, '_blank');
          } else if (href && href.includes('mailto:')) {
            window.location = href;
          } else {
            document.location.href = href;
          }
        },
      };

    case HANDLER_TYPES.CHANGE_TUTORIAL:
      return {
        [getEventHandler(action)]: () => {
          // @ts-ignore
          const { tutorialId } = action.handler;
          logError('TODO -> change tutorial', tutorialId);
        },
      };

    case HANDLER_TYPES.OPEN_GUIDE:
      return {
        [getEventHandler(action)]: () => {
          // @ts-ignore
          const { guideId } = action.handler;
          logError('TODO -> open guide', guideId);
        },
      };

    default:
      return {};
  }
};

type Options = {
  trackingIdentifiers: TrackingIdentifiers;
  provider: CanduProviderContextType;
  document: Document;
};

/**
 * Go through the `actions` of a given node and create all the
 * action handlers for the actions contained in that node.
 *
 * if no actions are found, returns an empty object.
 */
export const getActionHandlers = (node: EditorNode, options: Options) => {
  if (node.type === 'Link' && node.props?.href) {
    let eventName = `${node.props.href} Link Clicked`;
    // Get the node inside of the link element, and get that content
    // to set as the event name.
    const linkChildId = node?.nodes?.[0];
    if (linkChildId) {
      const linkChild = options.document.nodes[linkChildId];

      if (linkChild?.type === 'Text' && linkChild?.props?.children) {
        eventName = `${linkChild.props.children} Link Clicked`;
      }
    }

    return {
      onClick: () =>
        options.provider.eventing.track(EVENT_NAMES.TUTORIAL_INTERACTION, {
          trigger: TRIGGERS.ON_CLICK,
          category: node.type,
          eventName,
          handler: {
            href: node.props?.href,
            type: HANDLER_TYPES.NAVIGATE,
          },
        }),
    };
  }

  // no actions, no handlers
  if (!node.data || !node.data.actions) {
    return {};
  }

  const actionTracker = (action: Action, trackingIdentifiers?: TrackingIdentifiers) => {
    options.provider.eventing.track(EVENT_NAMES.TUTORIAL_INTERACTION, {
      ...action,
      ...(trackingIdentifiers || {}),
    });
  };

  return node.data.actions
    .map((action: Action) => getActionHandler(action, actionTracker, options.trackingIdentifiers))
    .reduce((acc: object, cur: object) => ({ ...acc, ...cur }), {});
};
