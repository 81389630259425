import React, { useEffect, useState } from 'react';
import {
  RESOURCES,
  TutorialProps,
  tutorialValidation,
  internalEventing,
  METRICS,
  TutorialSlug,
  TutorialDocument as TutorialDocumentType,
  TrackingIdentifiers,
  ApiError,
  isApiError,
} from '@candulabs/core';

import { useStyleguide } from '../Styleguide/useStyleguide';
import { TutorialContext } from '../TutorialContext';
import { useCanduContext } from '../CanduProviderContext';
import { Styleguide } from '../../types';
import { useEventing, useFetch } from '../../hooks';
import { ErrorBoundary } from '../ErrorBoundary';
import { PreviewWrapper } from '../PreviewWrapper';
import { TutorialDocument } from '../TutorialDocument';

interface Props extends TutorialProps {
  // We write `slug` prop here so that the documentation can pick it up.
  // TODO: fix the docs so it can pick up the props in the interface.
  /** Render a Tutorial by providing a slug. */
  slug?: TutorialSlug;

  // We write `styleguide` prop here so that the documentation can pick it up.
  /** Optional. Override the `Styleguide` for this `Tutorial`. */
  styleguide?: Partial<Styleguide>;

  /** @hidden Optional portalId for tracking purposes */
  portalId?: number;

  /** @hidden Optional portalId for tracking purposes */
  segmentId?: string;

  /** @hidden Optional tutorial document */
  tutorialDocument?: TutorialDocumentType;

  trackingIdentifiers?: TrackingIdentifiers;
}

export const Tutorial = (props: Props) => {
  const {
    tutorialId,
    contentHashId,
    slug,
    styleguide: customStyleguide,
    tutorialDocument: tutorialDocumentProp,
    trackingIdentifiers,
  } = tutorialValidation(props);

  const [
    tutorialPreviewDocument,
    setTutorialPreviewDocument,
  ] = useState<TutorialDocumentType | null>(props.tutorialDocument || null);
  const eventing = useEventing();
  const { clientToken } = useCanduContext();
  const { error, result, loadTime } = useFetch<TutorialDocumentType | ApiError>(
    () => RESOURCES.tutorialDocument(props, clientToken, eventing),
    [props],
  );

  const tutorialDocument = !error && result;

  const styleguide = useStyleguide(customStyleguide).all();

  useEffect(() => {
    setTutorialPreviewDocument(tutorialDocumentProp || null);
  }, [tutorialDocumentProp]);

  useEffect(() => {
    if (!error) {
      return;
    }

    internalEventing(eventing).tutorialDocumentLoadingError(props);
  }, [error]);

  useEffect(() => {
    if (loadTime) {
      eventing.track(METRICS.TUTORIAL_LOAD_TIME, {
        value: loadTime,
      });
    }
  }, [loadTime]);

  // TODO: add better documents
  if (!tutorialDocument || isApiError(tutorialDocument)) {
    return null;
  }

  return (
    <ErrorBoundary type="tutorial">
      <PreviewWrapper
        type="tutorial"
        currentTutorialId={
          tutorialPreviewDocument ? tutorialPreviewDocument.id : tutorialDocument.id
        }
        onTutorialChange={(tutorial) => setTutorialPreviewDocument(tutorial)}
      >
        <TutorialContext.Provider value={{ styleguide, tutorialId, contentHashId, slug }}>
          <TutorialDocument
            slug={tutorialDocument.slug}
            contentType={slug ? 'Tutorial' : 'Portal'}
            tutorialDocument={tutorialPreviewDocument || tutorialDocument}
            trackingIdentifiers={trackingIdentifiers}
          />
        </TutorialContext.Provider>
      </PreviewWrapper>
    </ErrorBoundary>
  );
};
