export const includeHTMLWhitespaces = (str: string) => {
  return str.replace(/\s/g, '&nbsp;');
};

export const stripHTML = (text: string) => {
  // sanity check for super old browsers
  // Just return the text if DOMParser is not available in the DOM API
  if (!DOMParser) {
    return text;
  }

  // Strip all HTML entities from text
  const doc = new DOMParser().parseFromString(text, 'text/html');

  return doc.body.textContent || '';
};
