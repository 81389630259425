import uniq from 'lodash/uniq';

import { CardCanduState, CardResolver, StateResolverType } from '../../models';
import { ENDPOINTS, API_BASE_URL } from '../../networking/constants';
import { StateResolverInstance } from './StateResolverInstance';
import { ApiDataLoader } from '../../utils/ApiDataLoader';

const CARD_API = API_BASE_URL + ENDPOINTS.cards;

export class CardResolverInstance extends StateResolverInstance<CardCanduState, CardResolver> {
  async retrieve(context, providerId) {
    const dataloader = ApiDataLoader.get('cardStateApi', async (itemIds: string[]) => {
      // Batch load items
      const items: CardCanduState[] = await fetch(
        `${CARD_API}?token=${context.clientToken}&finder=byIds&ids=${uniq(itemIds).join(
          ',',
        )}&userId=${context.userId}`,
      ).then((response) => response.json());
      // Result has to be same length as itemIds so if item is not found, we return undefined
      return itemIds.map((itemId) => items.find((item) => item.id === itemId));
    });

    const item = await dataloader.load(providerId);
    const defaultState = {
      id: providerId,
      state: {
        dismissed: false,
      },
    };

    return item || defaultState;
  }

  update(context, providerId, update) {
    return fetch(`${CARD_API}/${providerId}?token=${context.clientToken}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        state: update.state,
        userId: context.userId.toString(),
      }),
    }).then((response) => response.json());
  }
}

StateResolverInstance.register(StateResolverType.CardApi, CardResolverInstance);
