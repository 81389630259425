import memoize from 'lodash/memoize';

import { mergeStyleguidesFromContext, StyleguideComponents } from '@candulabs/core';
import { usePortalContext } from '../PortalContext';
import { useTutorialContext } from '../TutorialContext';
import { StyleguideClient } from '../../types';
import { useCanduContext } from '../CanduProviderContext';

import { createStyleguide } from './createStyleguide';

const createStyleguideClient = memoize(
  (contexts, overrideStyleguide): StyleguideClient =>
    createStyleguide(mergeStyleguidesFromContext(contexts, overrideStyleguide)),
);

// TODO: review if we actually need a StyleguideClient
export const useStyleguide = (styleguide: Partial<StyleguideComponents> = {}): StyleguideClient => {
  const provider = useCanduContext();
  const tutorial = useTutorialContext();
  const portal = usePortalContext();

  return createStyleguideClient({ provider, tutorial, portal }, styleguide);
};
