import isObject from 'lodash/isObject';
import { Eventing } from '@candulabs/eventing';
import {
  ClientToken,
  isContentHashId,
  isTutorialId,
  isTutorialSlug,
  TutorialId,
  TutorialDocument,
  UserId,
  ContentDocument,
  ApiError,
  PortalAsset,
} from '../models';

import { TutorialProps } from '../inputValidation';

import { CDN_BASE_URL, ENDPOINTS } from './constants';
import { fetchFromApi, fetchFromCdn, getTutorialDocument } from './endpoints';
import { LOGGER, EVENT_NAMES, ERROR_MESSAGES } from '../internalEventing';

export const RESOURCES = {
  tutorialDocumentLatestSaved: async (clientToken: ClientToken, tutorialId: TutorialId) =>
    fetchFromApi(`${ENDPOINTS.tutorialDocuments}/${tutorialId}?token=${clientToken}`),

  portalAsset: async (
    clientToken: ClientToken,
    slug: string,
    segmentIds?: string[],
  ): Promise<TutorialDocument | PortalAsset | ApiError | null> => {
    if (!segmentIds?.length) {
      return fetchFromCdn(`/portals/${clientToken}/${slug}/head.json`);
    }

    const queryString = `?segmentIds=${segmentIds.join(',')}`;
    return fetchFromCdn(`/portalMemberships/${clientToken}/${slug}/head.json${queryString}`);
  },

  contentDocument: async (
    clientToken: ClientToken,
    slug: string,
    segmentIds?: string[],
  ): Promise<ContentDocument | ApiError | null> => {
    const queryString = segmentIds?.length ? `?segmentIds=${segmentIds.join(',')}` : '';
    const response = await fetch(
      `${CDN_BASE_URL}/contentDocument/${clientToken}/${slug}.json${queryString}`,
    );
    return response.ok ? response.json() : null;
  },

  segmentMembership: async (clientToken: ClientToken, userId: UserId) =>
    fetchFromApi(`${ENDPOINTS.segmentMembership}/${userId}?token=${clientToken}`),

  segmentDefinition: async (clientToken: ClientToken) =>
    fetchFromApi(`${ENDPOINTS.segmentsDefinition}?token=${clientToken}`),

  tutorialMetadata: async (clientToken: ClientToken) =>
    fetchFromApi(`${ENDPOINTS.tutorialMetadata}?token=${clientToken}`),

  /** composite methods */
  tutorialDocument: (
    tutorialProps: TutorialProps,
    clientToken: ClientToken,
    eventing: Eventing,
  ) => {
    const { contentHashId, tutorialId, tutorialDocument, slug } = tutorialProps;

    const endpoints = getTutorialDocument(clientToken);

    if (isTutorialSlug(slug)) {
      return endpoints.bySlug(slug);
    }
    // IMPORTANT. DO NOT change the order.of these statements
    // tutorial id should be fetched if and only if there is
    if (isTutorialId(tutorialId)) {
      return endpoints.byId(tutorialId);
    }
    if (isContentHashId(contentHashId)) {
      return endpoints.byContentHash(contentHashId);
    }
    if (isObject(tutorialDocument)) {
      return Promise.resolve(tutorialDocument);
    }

    LOGGER.error(ERROR_MESSAGES[EVENT_NAMES.NO_VALID_TUTORIAL_IDENTIFIER]);
    eventing.track(EVENT_NAMES.NO_VALID_TUTORIAL_IDENTIFIER, {
      slug: tutorialProps.slug,
    });

    return null;
  },
};
