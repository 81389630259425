import React from 'react';
// NOTE: we could use 'react-player/lazy' but UMD and IIFE targets don't support it
import ReactPlayer from 'react-player';

import { logError, PlayerData } from '@candulabs/core';
import { StyleguideProps } from '../../../types';

const defaultStyle = {
  width: '640px',
  height: '360px',
};

export const Player = ({
  attributes: { width, height, src, aspectRatio, style, ...otherAttributes },
}: StyleguideProps<PlayerData>) => {
  if (aspectRatio) {
    const [divider, value] = aspectRatio.split(':');
    const paddingTop = `${(parseInt(value, 10) / parseInt(divider, 10)) * 100}%`;

    return (
      <div style={{ ...style, width, height }}>
        <div style={{ position: 'relative', paddingTop }}>
          <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
            <ReactPlayer
              {...otherAttributes}
              controls
              url={src}
              width="100%"
              height="100%"
              onError={logError}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <ReactPlayer
      {...otherAttributes}
      style={style}
      controls
      url={src}
      width={width || defaultStyle.width}
      height={height || defaultStyle.height}
      onError={logError}
    />
  );
};
