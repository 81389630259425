import { BrowserClient, Hub } from '@sentry/browser';
import { ClientToken, UserId } from './types/Input';
import { Event, EventType, PackageInfo, ContextAdapter } from './types/Event';

import event from './event';
import { makeAJAXRequest } from './utils';
import { VERSION } from './injections';
import { SENTRY_DSN } from './constants';

export interface Eventing {
  identify: (userId: UserId, traits?: object) => any;
  page: (pageName: string, properties?: object) => any;
  screen: (screenName: string, properties?: object) => any;
  track: (eventName: string, properties?: object) => any;
  error: (error: any, data?: any) => any;
}

export const noOpEventing: Eventing = {
  identify: () => null,
  page: () => null,
  screen: () => null,
  track: () => null,
  error: () => null,
};

let sentryClient: BrowserClient;
let sentryHub: Hub;

type EventingFactoryOptions = {
  url: string;
  disableTracking: boolean;
  contextAdapter?: ContextAdapter;
};

export const eventingFactory = (
  clientToken: ClientToken,
  userId: UserId,
  options: Partial<EventingFactoryOptions> = {},
  sdk?: PackageInfo,
): Eventing => {
  const { url, disableTracking, contextAdapter } = options;

  if (disableTracking) {
    return noOpEventing;
  }

  const eventingRequest = (evt: Event) => makeAJAXRequest(clientToken, evt, url);

  if (!sentryClient) {
    sentryClient = new BrowserClient({
      dsn: SENTRY_DSN,
      release: VERSION,
    });
    sentryHub = new Hub(sentryClient);
  }

  return {
    identify: (identifyUserId: UserId, traits?: object) =>
      eventingRequest(event(identifyUserId, EventType.Identify, { traits, sdk, contextAdapter })),

    page: (pageName: string, properties?: object) =>
      eventingRequest(event(userId, EventType.Page, { pageName, properties, sdk, contextAdapter })),

    screen: (screenName: string, properties?: object) =>
      eventingRequest(
        event(userId, EventType.Screen, { screenName, properties, sdk, contextAdapter }),
      ),

    track: (eventName: string, properties?: object) =>
      eventingRequest(
        event(userId, EventType.Track, { eventName, properties, sdk, contextAdapter }),
      ),

    error: (error: any, data: any) => {
      sentryHub.setUser({ id: userId?.toString() });
      sentryHub.captureException(error, { data });
    },
  };
};
