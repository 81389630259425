import React from 'react';

export const SegmentIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9845 19.2689L10.6841 15.2846L12.9845 11.3003L17.5851 11.3003L19.8855 15.2846L17.5851 19.2689L12.9845 19.2689Z"
        stroke="#BFA6F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.98445 14.2689L1.68412 10.2846L3.98445 6.30028L8.58512 6.30028L10.8855 10.2846L8.58512 14.2689L3.98445 14.2689Z"
        stroke="#BFA6F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9845 9.26888L10.6841 5.28458L12.9845 1.30028L17.5851 1.30028L19.8855 5.28458L17.5851 9.26888L12.9845 9.26888Z"
        stroke="#BFA6F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
