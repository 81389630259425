import { ClientToken, UserId } from '../eventing';
import { PreviewContent } from '../sdk';

export enum MESSAGES {
  BOOTSTRAP = 'Bootstrap',
  CHANGE_POSITION = 'ChangePosition',
  CLOSE_PREVIEW = 'ClosePreview',
  PREVIEW_LOADED = 'PreviewLoaded',
  SET_AVALIABLE_PORTALS = 'SetAvaliablePortals',
  SET_CONTENT = 'SetContent',
  SET_SELECTED_ELEMENT = 'SetSelectedElement',
  SET_VIEW_MODE = 'SetViewMode',
  ADD_ELEMENT = 'AddElement',
  REMOVE_ELEMENT = 'RemoveElement',
  UPDATE_ELEMENT = 'UpdateElement',
  SET_ELEMENT = 'SetElement',
}

const message = (type: MESSAGES, obj: object = {}) => ({ type, ...obj });

export const messages = {
  bootstrap: (clientToken: ClientToken, userId: UserId, version?: string, preview?: string) =>
    message(MESSAGES.BOOTSTRAP, { clientToken, userId, version, preview }),
  changePosition: (mouseEvent: string, top: number) =>
    message(MESSAGES.CHANGE_POSITION, { mouseEvent, top }),
  closePreview: () => message(MESSAGES.CLOSE_PREVIEW),
  previewLoaded: () => message(MESSAGES.PREVIEW_LOADED),
  setAvaliablePortals: (avaliablePortals) =>
    message(MESSAGES.SET_AVALIABLE_PORTALS, { avaliablePortals }),
  addElement: (element) => message(MESSAGES.ADD_ELEMENT, { element }),
  removeElement: (id) => message(MESSAGES.REMOVE_ELEMENT, { id }),
  updateElement: (element) => message(MESSAGES.UPDATE_ELEMENT, { element }),
  setContent: (content: PreviewContent) => message(MESSAGES.SET_CONTENT, { content }),
  setViewMode: (viewMode) => message(MESSAGES.SET_VIEW_MODE, { viewMode }),
  setSelectedElement: (element) => message(MESSAGES.SET_SELECTED_ELEMENT, { element }),
};
