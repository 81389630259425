import { CheckListScope } from '../notebook/CheckList';

export enum StateResolverType {
  CanduCdn = 'CanduCdn',
  CheckListApi = 'CheckListApi',
  CardApi = 'CardApi',
}

export type StateResolver<T extends StateResolverType = any, O extends Record<string, any> = {}> = {
  type: StateResolverType;
} & O;

// The resolver stores its options along with its type (ie: { type: '...', ...options })
export type ResolverOptions<R extends StateResolver> = Omit<R, 'type'>;

export type CanduCdnStateResolver = StateResolver<StateResolverType.CanduCdn>;

export interface CheckListResolverOptions {
  scope?: CheckListScope;
  scopeTrait?: string;
}

export type CheckListResolver = StateResolver<
  StateResolverType.CheckListApi,
  CheckListResolverOptions
>;

export type CardResolver = StateResolver<StateResolverType.CardApi>;
