import { useEffect, useRef, useState } from 'react';

interface UseFetchOptions {
  /* If set to false, we will skip setting loading=true before fetching the Promise */
  loading: boolean;
}

interface FetchState<R> {
  result: R | null;
  loading: boolean;
  error: object | null;
  loadTime: number;
}

export const useFetch = <R>(
  getPromise: () => Promise<R> | R | null,
  deps: any[] = [],
  options?: Partial<UseFetchOptions>,
) => {
  const [state, setState] = useState<FetchState<R>>({
    result: null,
    // TODO: Do we actually need to know when a response is loading (so far we don't really use the `loading` property anywhere in the SDK)
    loading: false,
    error: null,
    loadTime: 0,
  });

  const optionsRef = useRef<UseFetchOptions>({
    loading: false,
    ...(options || {}),
  });

  useEffect(() => {
    if (!getPromise) {
      return;
    }

    const fetchAsync = async () => {
      try {
        if (optionsRef.current.loading) {
          setState({ ...state, loading: true });
        }

        const started = performance.now();
        const result = await Promise.resolve(getPromise());
        setState({
          ...state,
          result: (result as unknown) as R,
          loading: false,
          loadTime: performance.now() - started,
        });
      } catch (e) {
        setState({ ...state, error: e, loading: false });
      }
    };

    fetchAsync();
  }, deps);

  return state;
};
