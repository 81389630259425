import React, { useLayoutEffect, useMemo, useState } from 'react';
import {
  EVENT_NAMES,
  CardResolverInstance,
  logger,
  toCssSelector,
  HANDLER_TYPES,
  CATEGORIES,
  TRIGGERS,
} from '@candulabs/core';

import { StyleguideProps } from '../../../../types';
import { Cross } from './Cross';
import { useCanduContext } from '../../../CanduProviderContext';

/* eslint-disable */
export const Card = ({
  attributes: { children, isDismissible, ...attributes },
  api,
}: StyleguideProps<{ isDismissible?: boolean }>) => {
  const { eventing } = useCanduContext();
  const className = toCssSelector('card', {
    output: 'html',
  });

  const provider = useMemo(
    () => api.stateInstances?.find((instance) => instance.resolver instanceof CardResolverInstance),
    [],
  );
  const [cardState, setCardState] = useState({ dismissed: false });

  // Subscribe to state update
  useLayoutEffect(() => {
    if (!provider) {
      return;
    }

    return provider.subscribe((updatedItem) => {
      setCardState(updatedItem.state);
    });
  }, [provider]);

  if (cardState.dismissed) {
    return null;
  }

  return (
    <div
      {...attributes}
      className={className}
      style={{ position: 'relative', ...attributes.style }}
    >
      {isDismissible && (
        <a
          style={{ position: 'absolute', top: '6px', right: '6px', cursor: 'pointer' }}
          onClick={() => {
            if (!provider) {
              logger.warning('Card state provider not found');
              return;
            }

            eventing.track(EVENT_NAMES.TUTORIAL_INTERACTION, {
              category: CATEGORIES.NATIVE,
              trigger: TRIGGERS.ON_CLICK,
              handler: {
                type: HANDLER_TYPES.DISMISSED_CARD,
                cardId: api.nodeId,
              },
              eventName: `DismissedCard_${api.nodeId}`,
              ...api.trackingIdentifiers,
            });

            provider.mutate({
              id: provider.providerId,
              state: {
                dismissed: true,
              },
            });
          }}
        >
          <Cross />
        </a>
      )}
      {children}
    </div>
  );
};
