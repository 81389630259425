import React from 'react';
import { ImageData } from '@candulabs/core';

import { StyleguideProps } from '../../../types';
import { DEFAULT_ALT } from '../constants';

const defaultStyle = {
  maxWidth: '100%',
  maxHeight: '100%',
  width: 'auto',
  height: 'auto',
};

/**
 * Render an image.
 *
 * We don't recommend overriding this component unless you have a specific use-case
 */
export const Image = ({
  attributes: { width, height, src, className, alt, style },
  api,
}: StyleguideProps<ImageData>) => {
  const { nodeId, document } = api;
  const props = !!document && !!nodeId && document.nodes[nodeId].props;
  if (!props) {
    return null;
  }

  return (
    <img
      alt={alt || DEFAULT_ALT}
      src={src}
      className={className}
      style={{
        ...defaultStyle,
        ...style,
        width: width || defaultStyle.width,
        height: height || defaultStyle.height,
      }}
    />
  );
};
