import { useEffect } from 'react';

interface Handler {
  type: string;
  callback: (event: any) => void;
}

export const useDocumentEvent = (events: Handler[]) => {
  useEffect(() => {
    events.forEach((event) => {
      document.addEventListener(event.type, event.callback);
    });
    return () =>
      events.forEach((event) => {
        document.removeEventListener(event.type, event.callback);
      });
  }, [events]);
};
