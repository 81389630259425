import React from 'react';

interface Props {
  style?: any;
}

export const CanduLogo = ({ style }: Props) => {
  return (
    <svg
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2305 9.94897V14.7646C14.2305 15.1644 14.5639 15.4886 14.9753 15.4886H16.8912C17.3291 15.4886 17.6767 15.1223 17.6334 14.6984C17.3822 12.2785 15.9969 10.3531 14.2305 9.94897Z"
        fill="white"
      />
      <path
        d="M7.9147 15.4886H11.836C12.2473 15.4886 12.5808 15.1644 12.5808 14.7645V6.74829C9.67763 7.41302 7.42465 10.6807 7.17078 14.725C7.14513 15.1395 7.48834 15.4886 7.9147 15.4886Z"
        fill="white"
      />
      <path
        d="M22.4665 14.1927C21.3563 13.6879 20.0233 14.2107 19.4988 15.2865C19.3086 15.6769 19.0883 16.0458 18.8407 16.3924C18.5125 16.8516 17.9711 17.1224 17.3962 17.1224H7.28563C6.7142 17.1224 6.17462 16.8559 5.84733 16.401C5.11668 15.3871 4.64698 14.209 4.48599 12.9458C4.22505 10.906 4.79736 8.88949 6.09678 7.26767C7.3962 5.64585 9.26705 4.61394 11.3661 4.36026C13.083 4.15302 14.7814 4.48409 16.2763 5.31908C17.7084 6.11795 18.8681 7.35453 19.5474 8.80522C20.0543 9.88701 21.3776 10.4288 22.4939 9.94376C23.6076 9.45962 24.1082 8.18865 23.6102 7.10515C22.5558 4.80742 20.7327 2.84851 18.478 1.59044C16.1445 0.28765 13.4961 -0.229165 10.8203 0.0933072C7.54923 0.488013 4.63283 2.09779 2.60718 4.62512C0.581537 7.15244 -0.310102 10.2955 0.0959122 13.4755C0.501926 16.6555 2.15783 19.4907 4.75755 21.4599C6.94684 23.1178 9.5846 23.9941 12.3152 23.9941C12.8274 23.9941 13.344 23.9631 13.8615 23.9004C18.1109 23.387 21.7279 20.8244 23.535 17.0459C24.049 15.971 23.5704 14.6949 22.4665 14.1927Z"
        fill="white"
      />
    </svg>
  );
};
