import { PackageInfo } from '../models';

import { CanduProviderContextType } from '../contexts';
import { PreviewClient } from '../preview';
import { TutorialProps, PortalProps, ContentProps } from '../inputValidation';
import { SDK_INFO } from '../constants';

export type SingletonRenderProp<P = {}> = {
  element: HTMLElement;
} & P;

type SingletonRenderUnmountFunction = () => void;

type SingletonInfo = {
  core: PackageInfo;
  sdk: PackageInfo;
};

export abstract class SingletonAPI<P = {} | undefined> {
  abstract name: string;

  abstract version: string;

  constructor(props: P) {
    this.init(props);
  }

  getInfo(): SingletonInfo {
    return {
      core: {
        version: SDK_INFO.version,
        name: SDK_INFO.name,
      },
      sdk: {
        version: this.version,
        name: this.name,
      },
    };
  }

  abstract init(props: P);

  // A renderTutorial should mount a Tutorial and should return a method to unmount that Tutorial
  abstract renderTutorial(
    props: SingletonRenderProp<TutorialProps>,
  ): SingletonRenderUnmountFunction;

  // A renderPortal should mount a Portal and should return a method to unmount that Portal
  abstract renderPortal(props: SingletonRenderProp<PortalProps>): SingletonRenderUnmountFunction;

  abstract renderContent(props: SingletonRenderProp<ContentProps>): SingletonRenderUnmountFunction;

  abstract getProvider(): CanduProviderContextType;

  public attachClient(client: PreviewClient) {
    return this.provider.preview.attachClient(client);
  }

  public detachClient() {
    return this.provider.preview.detachClient();
  }

  public get provider() {
    return this.getProvider();
  }
}
