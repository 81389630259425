import { PreviewClient } from './types';
import { Observer, ObserverSubscriber } from '../utils';

export class PreviewContainer {
  onClientUpdateObserver = new Observer<{ client: PreviewClient | null }>();

  client: PreviewClient | null;

  attachClient(client: PreviewClient) {
    // TODO: check client's authentication information and stuff
    this.client = client;
    this.onClientUpdateObserver.notify({
      client,
    });
  }

  detachClient() {
    if (!this.client) {
      return;
    }
    this.client = null;
    this.onClientUpdateObserver.notify({
      client: null,
    });
  }

  onClientUpdate(subscriber: ObserverSubscriber<this['onClientUpdateObserver']>) {
    const unsubscriber = this.onClientUpdateObserver.subscribe(subscriber);

    if (this.client) {
      subscriber({
        client: this.client,
      });
    }

    return unsubscriber;
  }
}
