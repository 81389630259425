import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export interface ApiError {
  errorCode: string;
  msg?: string;
  details?: object;
}

export function isApiError(obj: any): obj is ApiError {
  // @ts-ignore
  return isObject(obj) && isString(obj.errorCode);
}
