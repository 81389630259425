import React from 'react';

export const Cross = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 6L18 18" stroke="#D4D8E4" strokeWidth="3" strokeLinecap="round" />
      <path d="M18 6L6 18" stroke="#D4D8E4" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
};
