import React, { FunctionComponent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  internalEventing,
  ProviderProps,
  providerValidation,
  attachStylesheet,
  METRICS,
  ClientToken,
  UserId,
} from '@candulabs/core';
import { Eventing, eventingFactory, noOpEventing } from '@candulabs/eventing';

import {
  defaultProviderContext,
  CanduProviderContext,
  CanduReactProviderContextType,
} from '../CanduProviderContext';
import { Candu } from '../Candu';
import { defaultStyleguide } from '../Styleguide';
import { Options, Styleguide } from '../../types';
import { useSegmentMembership } from '../../hooks/useSegmentMembership';
import { ErrorBoundary } from '../ErrorBoundary';
import { SDK_INFO } from '../../constants';

interface Props extends ProviderProps {
  /** The client token associated with the application we want to render */
  clientToken: ClientToken;
  /** Children to be rendered below the provider. */
  children: ReactNode;
  /** The userId of the customer you want to track. If not set an anonymous id will be used instead */
  userId?: UserId;
  /** Optional. Any `traits` that you want to track for the user. */
  traits?: Record<string, unknown>;
  /** Optional. Use `Styleguide` to customize how Candu renders in your application. */
  styleguide?: Partial<Styleguide>;
  /** @hidden A customer can optionally pass an eventing object to customize the way they pass events */
  eventing?: Eventing;
  /** @hidden A set of advanced options. They are hidden as these options should not be useful for general public */
  options?: Options;
}

/**
 * Entry point of the application. A CanduProvider is responsible for:
 * 1. Send an identify call that identifies the user and any traits associated to it.
 * 2. Send a request to SegmentMembership to get all the segmentIds that the user belongs to.
 *    These will be used in the Portal to route the user to the correct content.
 */
export const CanduProvider: FunctionComponent<Props> = (props: Props) => {
  const validatedProps = useMemo(() => providerValidation(props), [props]);
  const { children, clientToken, userId, options, styleguide, traits } = validatedProps;

  // initialize singleton
  Candu.init(validatedProps);

  // start the eventing library. If the client provites an eventing library, use  it.
  const eventingClient = props.eventing || eventingFactory(clientToken, userId, options, SDK_INFO);

  useEffect(() => {
    internalEventing(eventingClient).provider({ userId, traits, styleguide });
  }, [userId, traits]);

  // TODO: We should refactor this
  const contextRef = useRef(defaultProviderContext);
  const [context] = useState<CanduReactProviderContextType>({
    ...defaultProviderContext,
    userId,
    clientToken,
    // we merge the default styleguide in the context to avoid causing
    // circular dependencies in the build since
    // many components that use `useStyleguide` depend on the `defaultStyleguide`.
    styleguide: { ...defaultStyleguide, ...styleguide },
    traits,
    options,
    eventing: eventingClient,
  });

  // disable eventing when we're in preview mode
  if (contextRef.current.selectedElement) {
    context.eventing = noOpEventing;
  }

  contextRef.current = context;

  const segmentMembership = useSegmentMembership({
    clientToken,
    userId,
    options,
    eventing: eventingClient,
  });

  useEffect(() => {
    if (segmentMembership.loadTime) {
      eventingClient.track(METRICS.SEGMENT_MEMBERSHIP_LOAD_TIME, {
        value: segmentMembership.loadTime,
      });
    }
  }, [segmentMembership.loadTime]);

  // on mount
  useEffect(() => {
    // attach 3S stylesheet
    attachStylesheet(clientToken);
  }, []);

  return (
    <CanduProviderContext.Provider value={{ ...context, segmentMembership }}>
      <ErrorBoundary type="provider">{children}</ErrorBoundary>
    </CanduProviderContext.Provider>
  );
};
