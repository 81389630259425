import React, { useContext, useEffect, useState } from 'react';
import {
  TutorialId,
  TutorialDocument,
  DEFAULT_SEGMENT_ID,
  TutorialMetadata,
  RESOURCES,
  ForcePreviewPortal,
  ForcePreviewTutorial,
} from '@candulabs/core';

import { useFetch } from '../../hooks';
import { PortalAsset } from '../../types';
import { notNull } from '../../utils/guards';

import { CanduProviderContext } from '../CanduProviderContext';
import { Dropdown } from './Dropdown';
import { styles } from './PreviewWrapperStyles';
import { useTutorialSocket } from './useTutorialSocket';
import { SegmentIcon } from './SegmentIcon';

export interface PortalPreviewProps {
  type: 'portal';
  portalAsset?: PortalAsset;
  portalSlug?: string;
  currentTutorialId?: TutorialId;
  onSegmentChange: (tutorial: TutorialDocument | null) => void;
  children: JSX.Element;
  forcePreview?: ForcePreviewPortal | ForcePreviewTutorial;
}

interface SegmentDefinition {
  id: string;
  name: string;
}

interface SegmentItem extends SegmentDefinition {
  tutorialId: number | string;
}

export const PortalPreview = ({
  children,
  portalAsset: portalAssetProp,
  portalSlug,
  currentTutorialId,
  onSegmentChange,
  forcePreview,
}: PortalPreviewProps) => {
  const providerContext = useContext(CanduProviderContext);
  const [segmentList, setSegmentList] = useState<SegmentItem[]>([]);
  const { result: tutorials } = useFetch<TutorialMetadata[]>(() =>
    RESOURCES.tutorialMetadata(providerContext.clientToken),
  );
  const { result: fetchedPortalAsset } = useFetch<PortalAsset>(() =>
    // @ts-ignore
    portalSlug ? RESOURCES.portalAsset(providerContext.clientToken, portalSlug) : null,
  );
  const { clientToken } = providerContext;

  const accentColor = '#6310E5';
  const portalAsset = fetchedPortalAsset || portalAssetProp;

  useTutorialSocket({
    tutorialId: currentTutorialId,
    onTutorialSocket: (document) => onSegmentChange(document),
  });

  // reset tutorial on unmount
  useEffect(() => {
    return () => onSegmentChange(null);
  }, []);

  const loadSavedTutorial = ({ value: { tutorialId } }) => {
    const promise = RESOURCES.tutorialDocumentLatestSaved(clientToken, tutorialId);
    if (!promise) {
      return;
    }

    promise.then((result: TutorialDocument) => {
      onSegmentChange(result);
    });
  };

  const loadSegmentList = (portalAssetObject: PortalAsset) => {
    // get list of segments and map over portal asset
    const promise = RESOURCES.segmentDefinition(providerContext.clientToken);

    if (!promise) {
      return;
    }

    promise.then((segmentDefinitions: SegmentDefinition[]) => {
      const segmentNames: SegmentItem[] = [
        {
          id: DEFAULT_SEGMENT_ID,
          name: DEFAULT_SEGMENT_ID,
          tutorialId:
            portalAssetObject.content.find((item) => item.segmentId === DEFAULT_SEGMENT_ID)
              ?.tutorialId ?? '',
        },
        ...segmentDefinitions
          .map(({ id, name }: SegmentDefinition) => {
            const contentSegment = portalAssetObject.content.find((item) => item.segmentId === id);
            if (!contentSegment) {
              return null;
            }
            return { id, name, tutorialId: contentSegment.tutorialId };
          })
          .filter(notNull),
      ];

      setSegmentList(segmentNames);
    });
  };

  const loadForcePreview = () => {
    if (!forcePreview) {
      return;
    }

    if (forcePreview.type === 'Tutorial') {
      loadSavedTutorial({ value: { tutorialId: forcePreview.props.tutorialId } });
      return;
    }

    // fetch different portal asset
    const promise = RESOURCES.portalAsset(clientToken, forcePreview.props.slug);

    promise.then((previewPortalAsset: PortalAsset) => {
      loadSegmentList(previewPortalAsset);
      // load first tutorial from preview tutorial asset
      const defaultTutorialId = previewPortalAsset.content.find(
        (item) => item.segmentId === DEFAULT_SEGMENT_ID,
      )?.tutorialId;

      loadSavedTutorial({ value: { tutorialId: defaultTutorialId } });
    });
  };

  useEffect(() => {
    if (forcePreview) {
      loadForcePreview();
      return;
    }

    // on mount we load current tutorial for this segment in latest saved version
    loadSavedTutorial({ value: { tutorialId: currentTutorialId } });

    if (portalAsset) {
      loadSegmentList(portalAsset);
    }
  }, [forcePreview, portalAsset]);

  return (
    <div style={styles.containerStyle(accentColor)}>
      <div style={styles.toolBarStyle(accentColor)}>
        {forcePreview && forcePreview.type === 'Tutorial' && (
          <span style={styles.forcePreviewLabel}>
            Previewing tutorial:{' '}
            {tutorials?.find((item) => item.id === forcePreview.props.tutorialId)?.name ??
              forcePreview.props.tutorialId}
          </span>
        )}
        {forcePreview && forcePreview.type === 'Portal' && (
          <span style={styles.forcePreviewLabel}>Previewing portal: {forcePreview.props.slug}</span>
        )}
        {(!forcePreview || forcePreview.type === 'Portal') && (
          <Dropdown
            icon={SegmentIcon()}
            items={segmentList.map(({ id, name, tutorialId }) => ({
              value: { segmentId: id, tutorialId },
              id,
              selected: currentTutorialId === tutorialId,
              label: name,
            }))}
            onChange={loadSavedTutorial}
          />
        )}
      </div>
      <div style={styles.contentWrapper}>{children}</div>
    </div>
  );
};
