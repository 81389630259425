import React from 'react';
import { EmbedPortalData, StyleguideProps, CANDU_PORTAL_CLASS } from '@candulabs/core';

export const EmbedPortal = ({
  attributes: { slug, style },
  api,
}: StyleguideProps<EmbedPortalData>) => {
  return (
    <div className={CANDU_PORTAL_CLASS} style={style}>
      {api.render.portal({ slug })}
    </div>
  );
};
