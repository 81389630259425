import uuid from 'uuid/v4';

import {
  Context,
  Event,
  EventOptions,
  EventType,
  Page,
  Screen,
  Source,
  PackageInfo,
  ContextAdapter,
} from './types/Event';
import { UserId } from './types/Input';
import { clientLibrary } from './constants';
import storage from './storage';
import { getWindow } from './utils';

const page: () => Page = () => ({
  path: getWindow().location.pathname,
  url: getWindow().location.href,
  title: document.title,
  search: getWindow().location.search,
  referrer: document.referrer,
});

const eventScreen: () => Screen = () => ({
  height: getWindow().screen.height,
  width: getWindow().screen.width,
});

const context = (
  source?: Source,
  sdk?: PackageInfo,
  contextAdapter: ContextAdapter = (i) => i,
): Context =>
  contextAdapter({
    sdk,
    source,
    locale: navigator.language,
    screen: getWindow().screen && eventScreen(), // make it a function bc it might change in a session
    page: getWindow() && document && page(),
    userAgent: navigator.userAgent,
    library: clientLibrary,
    timezone: getWindow().Intl && getWindow().Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

const event = (userId: UserId, type: EventType, options: EventOptions = {}): Event => ({
  id: uuid(),
  anonymousId: storage.anonymousId.getOrCreate(),
  userId: userId && userId.toString(), // always transform it into a string
  type,
  context: context(options.source, options.sdk, options.contextAdapter),
  sentAt: new Date().toISOString(),
  eventName: options.eventName,
  pageName: options.pageName,
  screenName: options.screenName,
  properties: options.properties,
  traits: options.traits,
});

export default event;
