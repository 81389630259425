/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */
/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable  jsx-a11y/no-static-element-interactions  */

import React, { useState, useRef, useCallback } from 'react';
import { DropdownArrow } from './DropdownArrow';
import { styles } from './DropdownStyles';
import { useDocumentEvent } from './useDocumentEvents';

interface DropdownItem {
  value: any;
  id: string | number;
  label: string;
  selected?: boolean;
}

export interface DropdownProps {
  icon?: JSX.Element;
  items: DropdownItem[];
  onChange: (value: DropdownItem['value']) => void;
}

export const Dropdown = ({ icon, items, onChange }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<string | number | null>(null);
  const defaultItem = items.find((item) => item.selected);
  const defaultLabel = defaultItem ? defaultItem.label : 'Select an option';
  const ref = useRef<HTMLDivElement>(null);

  const toggleMenu = () => setIsOpen(!isOpen);
  const handleOnSelect = (selectedItem) => {
    onChange(selectedItem);
    toggleMenu();
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        return;
      }
      setIsOpen(false);
    },
    [ref],
  );

  useDocumentEvent([{ type: 'click', callback: handleClickOutside }]);

  return (
    <div ref={ref} style={styles.container} onClick={toggleMenu}>
      <div style={styles.header}>
        <div style={styles.icon}>{icon}</div>
        <span style={styles.label}>{defaultLabel}</span>
        {isOpen && (
          <ul style={styles.menu}>
            {items.map(({ label, value, id }) => (
              <li
                key={id}
                style={{
                  ...styles.menuItem,
                  ...styles[hoveredItem === id ? 'hoverState' : 'defaultState'],
                }}
                onClick={() => handleOnSelect({ label, value })}
                onMouseEnter={() => setHoveredItem(id)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {label}
              </li>
            ))}
          </ul>
        )}
        <DropdownArrow style={{ marginTop: 2 }} />
      </div>
    </div>
  );
};
