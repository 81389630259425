export enum StyleguideScope {
  Global = 'Global',
  Organization = 'Organization',
}

export enum CSSNodeTypes {
  Root = 'root',
  Rule = 'rule',
  Atrule = 'atrule',
  Decl = 'decl',
  Any = 'any',
}

export type ClassNameMap = {
  childClassNames?: Record<string, string>;

  // Ideally we should just create a unique classname (via a hash?)
  // But for now, we will just map the components props into a classname
  variants?: Record<string, (props: any) => string>;
};

export const stylguideClassNameMap: Record<string, ClassNameMap> = {
  button: {
    childClassNames: {
      icon: 'button-icon',
    },
    variants: {
      color: (value) => `color-${value}`,
      outline: (value) => `outline-${value}`,
    },
  },
  badge: {
    variants: {
      color: (value) => `color-${value}`,
      outline: (value) => `outline-${value}`,
    },
  },
  typography: {
    variants: {
      typography: (value) => `typography-${value}`,
    },
  },
};
