import React, { FunctionComponent } from 'react';

import { StyleguideProps } from '../../../types';
import { HtmlComponent } from './HtmlComponent';

/**
 * A View is the same as a `div` in HTML.
 *
 * We don't recommend overriding this elemenent in your Styleguide
 * unless you have a specific use-case.
 */
export const View: FunctionComponent<StyleguideProps> = (props) => {
  const Component = HtmlComponent('div');
  return <Component {...props} />;
};
