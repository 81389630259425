import {
  CanduProviderContextType,
  PreviewContainer,
  PreviewElement,
  WindowStream,
  SelectedElement,
} from '@candulabs/core';
import { Eventing } from '@candulabs/eventing';

import { ERROR_MESSAGES } from '../../constants';
import { logger } from '../../utils';

const logTrackingError = () => logger.error(ERROR_MESSAGES.EVENTING_NOT_INITIALIZED);

const eventing = {
  identify: logTrackingError,
  page: logTrackingError,
  screen: logTrackingError,
  track: logTrackingError,
  error: logTrackingError,
};

export interface CanduReactProviderContextType extends CanduProviderContextType {
  eventing: Eventing;
  stream: WindowStream | undefined;
  elements: Record<string, PreviewElement>;
  selectedElement?: SelectedElement;
  setStream: (stream: any) => void;
  setElements: (elements: any) => void;
  setSelectedElement: (selectedElement: any) => void;
}

export const defaultProviderContext: CanduReactProviderContextType = {
  userId: '',
  clientToken: '',
  styleguide: {},
  eventing,
  segmentMembership: {
    loading: false,
    result: undefined,
    error: undefined,
    loadTime: 0,
  },
  preview: new PreviewContainer(),
  options: {},
  traits: {},
  // specific to React-SDK
  // TODO: get rid of all these functions.
  stream: undefined,
  elements: {},
  selectedElement: undefined,
  setStream: () => null,
  setElements: () => null,
  setSelectedElement: () => null,
};
