import isArray from 'lodash/isArray';

import { DEFAULT_SEGMENT_ID, PortalAsset, SegmentTutorialTree } from '../models';

const createLookup = (array: string[]): object =>
  array.reduce((obj, item) => ({ ...obj, [item]: item }), {});

export const findMatchingSegment = (
  portalAsset: PortalAsset,
  segmentIds: string[],
): SegmentTutorialTree | undefined => {
  const segmentMembershipLookup = createLookup(segmentIds as string[]);

  // sanity check, never trust typescript in critical places...
  if (!isArray(portalAsset.content)) {
    return undefined;
  }

  // If the user only changes the segment of the portal
  return portalAsset.content.find(
    ({ segmentId: portalSegmentId }) =>
      !!segmentMembershipLookup[portalSegmentId] || portalSegmentId === DEFAULT_SEGMENT_ID,
  );
};
