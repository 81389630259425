import { EVENT_NAMES } from './events';

const PORTAL_ASSET_NOT_FOUND = `Could not find the requested Portal. This could happen because:
1. The Portal does not exist or it was deleted.
2. The Portal has not been made avaliable to be rendered.
3. CORS Authenticaton failed.
See http://udn.ac/portal-not-found for more information.\n`;

const EVENTING_NOT_INITIALIZED = `You tried to send an event, but you haven't initialized eventing.
This is happening because you called eventing outside the scope of the CanduProvider. It can be fixed by:
1. Adding CanduProvider to the root of the application.
2. Initializing a new Eventing object outside of Candu.
Read here for more context: http://udn.ac/tracking-not-init.\n`;

const PORTAL_NO_SEGMENT_IDS = `You have tried to render a Portal that doesn't contain any content.
Add content to your Portal: https://udn.ac/update-portals.\n`;

const NO_MATCHING_TUTORIALS =
  'The Tutorial will not be rendered because there are no matching segments for the given user.';

export const ERROR_MESSAGES = {
  EVENTING_NOT_INITIALIZED,

  [EVENT_NAMES.PORTAL_ASSET_NOT_FOUND]: PORTAL_ASSET_NOT_FOUND,
  [EVENT_NAMES.PORTAL_NO_SEGMENT_IDS]: PORTAL_NO_SEGMENT_IDS,
  [EVENT_NAMES.NO_MATCHING_TUTORIALS]: NO_MATCHING_TUTORIALS,

  [EVENT_NAMES.CIRCULAR_DEPENDENCY_EMBED_PORTAL]:
    'Circular dependency found on Embeded Portal. Please take a look at the Portal Content.',

  [EVENT_NAMES.CIRCULAR_DEPENDENCY_EMBED_TUTORIAL]:
    'Circular dependency found on Embeded Tutorial. Please take a look at the Tutorial Content.',
};
