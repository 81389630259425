import { useEffect } from 'react';
import { TutorialId, TutorialDocument } from '@candulabs/core';

import { useCanduContext } from '../CanduProviderContext';

interface Props {
  tutorialId?: TutorialId;
  onTutorialSocket: (tutorialDocument: TutorialDocument) => void;
}

const socketUrl = (clientToken: string, tutorialId: TutorialId) =>
  `wss://2jcfb7pjlf.execute-api.eu-west-1.amazonaws.com/prod?clientToken=${clientToken}&tutorialId=${tutorialId}`;

export const useTutorialSocket = ({ tutorialId, onTutorialSocket }: Props) => {
  const { clientToken } = useCanduContext();

  useEffect(() => {
    // don't open the socket in development mode or no tutorial id
    if (process.env.NODE_ENV === 'development' || !tutorialId) {
      return;
    }

    const webSocket = new WebSocket(socketUrl(clientToken, tutorialId));
    webSocket.onmessage = (message) => {
      const document = JSON.parse(message.data) as TutorialDocument;
      onTutorialSocket(document);
    };

    // customer will navigate away, so just close.
    // eslint-disable-next-line consistent-return
    return () => webSocket.close();
  }, [tutorialId]);
};
