import { Action } from './Actions';
import { NodeStates, StateProviders } from '../state';

export enum Mark {
  U = 'U',
  B = 'B',
  Em = 'Em',
  InlineCode = 'InlineCode',
}

export interface Data {
  actions?: Action[];
  custom?: {
    marks?: Mark[];
    variant?: string;
    [key: string]: any;
  };
}

export interface EditorNode {
  type: string;
  data?: Data;
  props?: Record<string, number | string>;
  css?: object;
  nodes?: string[];
  states?: NodeStates;
}

export type Nodes = Record<string, EditorNode>;

export interface Head {
  nodeType?: string;
  url: string;
}

export interface Document {
  rootNodeId: string;
  head?: Head[];
  nodes: Nodes;
  stateProviders?: StateProviders;
}

export interface TypographyNode extends EditorNode {
  type: 'Typography';
  data: {
    custom: {
      variant: string;
    };
  };
}
