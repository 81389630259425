import { EVENT_NAMES, ERROR_SLUGS } from './eventNames';

const PORTAL_ASSET_NOT_FOUND = `Could not find the requested Portal. This could happen because:
1. The Portal does not exist or it was deleted.
2. The Portal has not been made avaliable to be rendered.
3. CORS Authenticaton failed.
See http://udn.ac/portal-not-found for more information.\n`;

const unknownMessage = (
  errorCode: string,
) => `An error has occured while trying to parse your Tutorial.
Upgrade the SDK to the latest version to get rid of this error.
ErrorCode: ${errorCode}.`;

const EVENTING_NOT_INITIALIZED = `You tried to send an event, but you haven't initialized eventing.
This is happening because you called eventing outside the scope of the CanduProvider. It can be fixed by:
1. Adding CanduProvider to the root of the application.
2. Initializing a new Eventing object outside of Candu.
Read here for more context: http://udn.ac/tracking-not-init.\n`;

const PORTAL_NO_SEGMENT_IDS = `You have tried to render a Portal that doesn't contain any content.
Add content to your Portal: https://udn.ac/update-portals.\n`;

const NO_MATCHING_TUTORIALS =
  'The Tutorial will not be rendered because there are no matching segments for the given user.';

export const ERROR_MESSAGES = {
  EVENTING_NOT_INITIALIZED,

  [EVENT_NAMES.PORTAL_ASSET_NOT_FOUND]: PORTAL_ASSET_NOT_FOUND,
  [EVENT_NAMES.PORTAL_NO_SEGMENT_IDS]: PORTAL_NO_SEGMENT_IDS,
  [EVENT_NAMES.NO_MATCHING_TUTORIALS]: NO_MATCHING_TUTORIALS,

  [EVENT_NAMES.NO_VALID_TUTORIAL_IDENTIFIER]:
    '(Candu/Tutorial) You must define one of tutorialId, slug, commitId or contentHashId.',

  [EVENT_NAMES.TUTORIAL_LOADING_ERROR]: 'An error has occurred while trying to load the Tutorial',

  [EVENT_NAMES.UNKOWN_INLINE_NODE_TYPE]: unknownMessage(ERROR_SLUGS.UNKOWN_INLINE_NODE_TYPE),

  [EVENT_NAMES.UNKOWN_NODE_TYPE]: unknownMessage(ERROR_SLUGS.UNKOWN_NODE_TYPE),

  [EVENT_NAMES.CIRCULAR_DEPENDENCY_EMBED_PORTAL]:
    'Circular dependency found on Embeded Portal. Please take a look at the Portal Content.',

  [EVENT_NAMES.CIRCULAR_DEPENDENCY_EMBED_TUTORIAL]:
    'Circular dependency found on Embeded Tutorial. Please take a look at the Tutorial Content.',

  [EVENT_NAMES.PORTAL_ERROR]: 'An error has occured within Portal',

  [EVENT_NAMES.PROVIDER_ERROR]: 'An error has occured within Provider',

  [EVENT_NAMES.TUTORIAL_ERROR]: 'An error has occured within Tutorial',

  [EVENT_NAMES.RENDER_NODE_ERROR]: 'An error has occured while rendering tutorial node',

  [EVENT_NAMES.STYLEGUIDE_MAP_ERROR]: 'An error has occured while mapping node to styleguide',

  [EVENT_NAMES.STATE_FETCH_FAILED]: 'An error has occured while fetching state:',

  [EVENT_NAMES.STATE_UPDATE_FAILED]: 'An error has occured while updating state:',

  [EVENT_NAMES.SEGMENT_MEMBERSHIP_TIMEOUT]: 'Segment membership fetch has timed out',
};
