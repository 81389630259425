import map from 'lodash/map';
import { StateProviders } from '../models';

import { CanduProviderContextType } from '../contexts';
import { StateProviderInstance } from './StateProviderInstance';
import { StateResolverInstance } from './resolvers';

export function initStateProviders(
  stateProviders: StateProviders,
  context: CanduProviderContextType,
): StateProviderInstance[] {
  return map(stateProviders, (provider, providerId) => {
    const resolver = StateResolverInstance.fromJSON(provider.resolver);
    if (!resolver) {
      return null;
    }

    return new StateProviderInstance(context, providerId, resolver);
  }).filter((instance) => !!instance) as StateProviderInstance[];
}
