import { uniqBy } from 'lodash';
import { Metadata } from '../models/notebook';

export const injectToWindow = (nodeMetadata: Metadata) => {
  if (typeof window === 'undefined') {
    return;
  }

  const { clientToken } = nodeMetadata;
  const meta = {
    ...nodeMetadata,
    nodeId: `[data-candu-content=${nodeMetadata.contentType}-${nodeMetadata.slug}]`,
  };

  /**
   * if canduMetadata has not yet been injected into the
   * window, we inject it here for the first time
   */
  if (!window.canduMetadata) {
    window.canduMetadata = { [clientToken]: [meta] };
    return;
  }

  /**
   * if canduMetadata does not have the clientToken on the
   * object yet, we create a new object with both the previous
   * and the new clientToken
   */
  if (!window.canduMetadata[clientToken]) {
    window.canduMetadata = {
      ...window.canduMetadata,
      ...{ [clientToken]: [meta] },
    };
    return;
  }

  /**
   * When canduMetadata has the clientToken, we create a new array
   * with the previous items and the new nodeMetadata
   */
  window.canduMetadata = {
    ...window.canduMetadata,
    ...{
      [clientToken]: uniqBy([...window.canduMetadata[clientToken], ...[meta]], 'nodeId'),
    },
  };
};
