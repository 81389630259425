import React, { FunctionComponent } from 'react';
import { ButtonData, toCssSelector } from '@candulabs/core';

import { StyleguideProps } from '../../../types';

export const Button: FunctionComponent<StyleguideProps<ButtonData>> = ({
  attributes,
}: StyleguideProps<ButtonData>) => {
  return (
    <button
      type="button"
      {...attributes}
      className={toCssSelector('button', {
        variant: {
          color: attributes.color,
          outline: attributes.outline,
        },
        output: 'html',
      })}
      style={{ ...(attributes.style || {}) }}
    />
  );
};
