import uniq from 'lodash/uniq';
import {
  CheckListItemModel,
  CheckListItemStatus,
  CheckListScope,
  CheckListResolver,
  StateResolverType,
} from '../../models';
import { StateResolverInstance } from './StateResolverInstance';
import { logWarning } from '../../utils';
import { API_BASE_URL, ENDPOINTS } from '../../networking/constants';
import { ApiDataLoader } from '../../utils/ApiDataLoader';

const CHECKLIST_API = API_BASE_URL + ENDPOINTS.checkListItems;

function adaptResponse(response: any): CheckListItemModel {
  return { state: response.state, id: response.id };
}

type Trait = 'UserId' | 'AccountId';

function scopeToTrait(scope: CheckListScope): Trait {
  switch (scope) {
    case CheckListScope.Account:
      return 'AccountId';
    case CheckListScope.User:
    default:
      return 'UserId';
  }
}

export class CheckListResolverInstance extends StateResolverInstance<
  CheckListItemModel,
  CheckListResolver
> {
  private getScopeAndFieldFromContext(context) {
    const { scopeTrait } = this.options;
    let scope = this.options.scope || CheckListScope.User;
    let scopeField = context.userId.toString();

    if (scope === CheckListScope.Account) {
      if (scopeTrait && context.traits[scopeTrait]) {
        scopeField = context.traits[scopeTrait];
      } else {
        logWarning(`trait '${scopeTrait}' is not defined, falling back to user scope`);
        scope = CheckListScope.User;
      }
    }

    return { scope, scopeField };
  }

  async retrieve(context, providerId) {
    const { scope, scopeField } = this.getScopeAndFieldFromContext(context);

    const dataloader = ApiDataLoader.get('checklistState', async (itemIds: string[]) => {
      // Batch load items
      const items: CheckListItemModel[] = await fetch(
        `${CHECKLIST_API}?token=${context.clientToken}&byTrait=${scopeToTrait(
          scope,
        )}&field=${scopeField}&finder=byIds&ids=${uniq(itemIds).join(',')}`,
      )
        .then((response) => response.json())
        .then((results) => results.map(adaptResponse));
      // Result has to be same length as itemIds so if item is not found, we return undefined
      return itemIds.map((itemId) => items.find((item) => item.id === itemId));
    });

    const item = await dataloader.load(providerId);
    return item || { id: providerId, state: CheckListItemStatus.NotStared };
  }

  update(context, providerId, update) {
    const { scope, scopeField } = this.getScopeAndFieldFromContext(context);

    return fetch(`${CHECKLIST_API}/${providerId}?token=${context.clientToken}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...update, byTrait: scopeToTrait(scope), field: scopeField }),
    })
      .then((response) => response.json())
      .then(adaptResponse);
  }
}

StateResolverInstance.register(StateResolverType.CheckListApi, CheckListResolverInstance);
