import { Event } from './types/Event';
import { EVENT_API, EVENT_URL, windowObject } from './constants';
/**
 * Utility function to get timestamps and mock it in other tests
 */
export const timestamp = () => new Date().getTime();

/**
 * We create our implemetation of a JSON AJAX request to avoid
 * incorporating external libraries that we don't need.
 * @param {string} clientToken the api key we use to make the request.
 * @param {object} body optional json body to be send.
 */
export const makeAJAXRequest = (clientToken: string, event: Event, url?: string) => {
  const devUrl = url && `${url}${EVENT_API}`;
  const urlForRequest = `${devUrl || EVENT_URL}?token=${clientToken}`;

  fetch(urlForRequest, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    body: JSON.stringify(event),
  });
};

/**
 * We check whether the window object is available or not and in its abscence we
 * create a pollyfill object to ensure that the window object and values
 * we use in our event functions are available on both client and server side.
 * @returns {Window || pollyfillWindow }
 */
export const getWindow = () => (typeof window !== 'undefined' ? window : windowObject);

export default makeAJAXRequest;
