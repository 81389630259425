import { TutorialPublishedStates } from '@candulabs/core';
import React from 'react';
import { styles } from './PreviewWrapperStyles';

interface Props {
  state: TutorialPublishedStates;
}

export const ContentState = ({ state }: Props) => {
  if (state === 'Unsynced') {
    return <span style={styles.pending}>Pending Changes</span>;
  }
  if (state === 'Published') {
    return <span style={styles.published}>Published Content</span>;
  }

  return <span style={styles.draft}>Draft</span>;
};
