import * as injections from './injections';

import { SegmentId } from '../eventing';
import { TutorialId } from '../notebook';

export const PREVIEW_COOKIE_NAME = 'candu.preview';
export const PREVIEW_URL_PARAM = 'candu_preview';

export const PREVIEW_FRAME_ID = 'canduPreview';

export enum FRAME_SIZES {
  COLLAPSED = 'Collapsed',
  EXPANDED = 'Expanded',
  MINIMIZED = 'Minimized',
}

export enum PREVIEW_MODES {
  TUTORIAL = 'Tutorial',
  PORTAL = 'Portal',
}
export type PreviewMode = PREVIEW_MODES;

export interface PreviewContent {
  tutorialId?: TutorialId;
  segmentId?: SegmentId;
}

export type PreviewElementType = 'tutorial' | 'portal';

export type PreviewElement = {
  id: string;
  type: PreviewElementType;
  slug: string;
  previewContent: PreviewContent;
};

export type SelectedElement = {
  id: string;
  name: string;
  slug: string;
  type: PreviewElementType;
};

// location of the candu preview app bundle.
export const { PREVIEW_SCRIPT_URL } = injections;
