import React from 'react';

interface Props {
  style?: any;
}

export const DropdownArrow = ({ style }: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M4 3.06586L1.37354 0.252373C1.06552 -0.0775813 0.559034 -0.0849573 0.242278 0.235898C-0.0744781 0.556754 -0.081559 1.08434 0.226462 1.41429L3.27571 4.68066L3.27629 4.68129C3.67393 5.10667 4.32768 5.10617 4.72472 4.68021L7.77354 1.41429C8.08156 1.08434 8.07448 0.556754 7.75772 0.235898C7.44097 -0.0849573 6.93448 -0.0775813 6.62646 0.252373L4 3.06586Z"
        fill="#BFA6F4"
      />
    </svg>
  );
};
