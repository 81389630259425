/* eslint-disable max-classes-per-file, consistent-return */
import { SingletonAPI } from './SingletonAPI';

export function createSingleton<T extends SingletonAPI>(ApiConstructor: { new (...args: any): T }) {
  let singleton;

  // Redirect method calls to the SingletonAPI
  const reflectApiMethod = (name: keyof typeof SingletonAPI['prototype']) => {
    return (...args) => {
      if (singleton.api) {
        return (singleton.api[name] as any)(...args);
      }

      // eslint-disable-next-line
      console.warn(
        `Please initialize the SDK by calling Candu.init() before attempting to call ${name}`,
      );
    };
  };

  singleton = class Candu {
    // Contains a list of subscribers functions that will be called when the provider value exist
    static subscriberCallbacks: (() => void)[] = [];

    static api: T;

    static init(...args: Parameters<T['init']>): Candu {
      if (!this.api) {
        Candu.api = new ApiConstructor(...args);
      }

      // TODO: clean this up
      Candu.subscriberCallbacks.forEach((callback) => callback());
      Candu.subscriberCallbacks = [];

      if (typeof window !== 'undefined') {
        window.postMessage(
          {
            source: 'CANDU_SINGLETON',
            payload: {
              type: 'INIT',
            },
          },
          window && window.origin ? window.origin : '*',
        );
      }

      return Candu;
    }

    static onProviderReady(callback: () => void) {
      if (!Candu.api) {
        Candu.subscriberCallbacks.push(callback);
        return;
      }

      return callback();
    }

    static renderTutorial = reflectApiMethod('renderTutorial');

    static renderPortal = reflectApiMethod('renderPortal');

    static renderContent = reflectApiMethod('renderContent');

    static attachClient = reflectApiMethod('attachClient');

    static detachClient = reflectApiMethod('detachClient');

    static getProvider = reflectApiMethod('getProvider');

    static getInfo = reflectApiMethod('getInfo');
  };

  if (typeof window !== 'undefined') {
    (window as any).Candu = singleton;
  }

  return singleton;
}
