import React from 'react';

import { StyleguideProps } from '../../../types';

const defaultStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  borderTop: '1px solid #edeff3',
  height: '0',
};

/**
 * Thematic break between paragraph-level elements,
 *
 * Equivalent of `<hr />` in HTML.
 */
export const Spacing = ({ attributes }: StyleguideProps) => (
  <hr {...attributes} style={{ ...defaultStyle, ...attributes.style }} />
);
