import React, { FunctionComponent } from 'react';
import { LinkData, toCssSelector } from '@candulabs/core';

import { StyleguideProps } from '../../../types';

/**
 * A Link is used to link somewhere else in your page.
 *
 * Equivalent of `<a>` element in HTML.
 */
export const Link: FunctionComponent<StyleguideProps<LinkData>> = ({
  attributes: { href, target, ...otherAttributes },
}: StyleguideProps<LinkData>) => {
  const className = toCssSelector('link', {
    output: 'html',
  });

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      {...otherAttributes}
      className={className}
      href={href}
      target={href.includes('http') && !target ? '_blank' : target}
    />
  );
};
