/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import {
  CheckListItemData,
  CheckListItemStatus,
  toCssSelector,
  EVENT_NAMES,
  StateProviderInstance,
  StateProviderInstances,
  StyleguideProps,
  CheckListResolverInstance,
} from '@candulabs/core';
import { useCanduContext } from '../../../CanduProviderContext';

import { Arrow } from './Arrow';
import { itemChildrenStyle, itemHeaderStyle, itemStyle, expanderStyle } from './styles';
import { StatusButton } from './StatusButton';

const statusNames = [CheckListItemStatus.NotStared, CheckListItemStatus.Done];

const nextStatus = (status: CheckListItemStatus) =>
  statusNames[(statusNames.indexOf(status) + 1) % statusNames.length];

const getStateProvider = (
  stateInstances: StateProviderInstances,
): StateProviderInstance<CheckListResolverInstance> => {
  const provider = stateInstances.find(
    (instance) => instance.resolver instanceof CheckListResolverInstance,
  );
  if (!provider) {
    throw new Error("Couldn't find CheckListApi state provider");
  }
  return provider;
};

const ChildrenContainer = ({ expanded, children }) => {
  const style = expanded
    ? itemChildrenStyle
    : { ...itemChildrenStyle, maxHeight: 0, padding: '0px 48px' };
  return <div style={style}>{children}</div>;
};

interface Props extends StyleguideProps<CheckListItemData> {
  expandByDefault?: boolean;
}

export const CheckListItem = ({
  attributes: { title, style, children, ...attributes },
  api: { stateInstances, trackingIdentifiers },
  expandByDefault,
}: Props) => {
  const { eventing } = useCanduContext();
  const [status, setStatus] = useState(CheckListItemStatus.NotStared);
  const [expanded, setExpanded] = useState(!!expandByDefault);

  if (!stateInstances) {
    throw new Error('no state provided');
  }
  const provider = getStateProvider(stateInstances);

  // Subscribe to state update
  useEffect(() => {
    return provider.subscribe((updatedItem) => {
      setStatus(updatedItem.state);
    });
  }, [provider]);

  // Automatically expand if parent says so
  useEffect(() => {
    if (children) setExpanded(!!expandByDefault);
  }, [expandByDefault]);

  // Toggle status
  const changeStatus = () => {
    const state = nextStatus(status);
    provider.mutate({ state });
    eventing.track(EVENT_NAMES.CHECKLIST_ITEM_STATE_UPDATED, {
      itemId: provider.providerId,
      state,
      title,
      ...trackingIdentifiers,
    });
  };

  const headerStyle = {
    ...itemHeaderStyle,
    borderBottom: children && expanded ? '1px solid var(--color-lines)' : 'none',
  };

  const titleStyle = {
    flexGrow: 1,
    fontWeight: 600,
    cursor: children ? 'pointer' : 'auto',
  };

  const titleClassName = toCssSelector('typography', {
    variant: { variant: 'P' },
    output: 'html',
  });

  const toggleExpand = (event: React.MouseEvent) => {
    setExpanded(!expanded);
    event.stopPropagation();
  };

  return (
    <div style={{ ...itemStyle, ...style }} {...attributes}>
      <div style={headerStyle} onClick={children && toggleExpand}>
        <StatusButton onChangeStatus={changeStatus} status={status} />

        <div className={titleClassName} style={titleStyle}>
          {title}
        </div>

        {children && (
          <button type="button" onClick={toggleExpand} style={expanderStyle}>
            <Arrow direction={expanded ? 'up' : 'down'} />
          </button>
        )}
      </div>

      {children && <ChildrenContainer expanded={expanded}>{children}</ChildrenContainer>}
    </div>
  );
};
