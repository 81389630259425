import React, { Fragment } from 'react';
import isObject from 'lodash/isObject';
import invariant from 'invariant';

import { Styleguide, StyleguideClient, StyleguideProps } from '../../types';
import { tags, specialComponents } from './constants';

export const createStyleguide = (styleguide: Styleguide): StyleguideClient => {
  invariant(isObject(styleguide), '(createStyleguide) styleguide must be undefined or an object');

  const retrieveComponent = (type: string) => {
    if (styleguide[type]) {
      return styleguide[type];
    }
    if (specialComponents[type]) {
      return specialComponents[type];
    }
    const indexOfTag = tags.indexOf(type) || tags.indexOf(type.toLowerCase());
    return tags[indexOfTag];
  };

  return {
    getComponent: (type: string) => {
      const Component = retrieveComponent(type);

      if (typeof Component === 'string') {
        return ({ api, attributes }) => <Component key={api.nodeId} {...attributes} />;
      }

      if (Component === Fragment) {
        return ({ api, attributes }: StyleguideProps) => (
          <Fragment key={api.nodeId}>{attributes.children}</Fragment>
        );
      }

      return Component;
    },
    all: () => styleguide,
  };
};
