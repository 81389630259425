import { CSSProperties } from 'react';

const expandTransition = 'all .35s ease-out 0s';

export const itemStyle: CSSProperties = {
  border: '1px solid var(--color-lines)',
  borderRadius: '4px',
  marginBottom: '16px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
};

export const itemHeaderStyle: CSSProperties = {
  padding: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexGrow: 1,
  boxSizing: 'border-box',
  color: 'var(--color-neutral)',
};

export const itemChildrenStyle: CSSProperties = {
  padding: '16px 48px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
  transition: expandTransition,
};

export const defaultStatusStyle: CSSProperties = {
  borderRadius: '20px',
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  marginRight: '16px',
  appearance: 'none',
  outline: 'none',
  padding: 0,
  transition: 'all .1s ease-out 0s',
};

export const expanderStyle: CSSProperties = {
  appearance: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  background: 'none',
  padding: '0px',
  display: 'flex',
};

export const groupStyle: CSSProperties = {
  marginBottom: '48px',
};

export const progressBarContainerStyle: CSSProperties = {
  width: '100%',
  height: '4px',
  marginBottom: '16px',
  backgroundColor: '#EDEFF3',
};

export const progressBarStyle: CSSProperties = {
  height: '100%',
  backgroundColor: '#59C3C3',
  transition: expandTransition,
};
