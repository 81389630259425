import { Document, TrackingIdentifiers, EditorNode } from '../models';

import { EVENT_NAMES, ERROR_MESSAGES } from '../internalEventing';
import { logger } from '../utils';
import { CanduProviderContextType } from '../contexts';

import { getActionHandlers } from './actions';
import { StyleguideComponent, StyleguideAttributes, DocumentApi, StyleguideProps } from './types';
import { PortalProps, TutorialProps } from '../inputValidation';
import { StateProviderInstances } from '../state';

type Options = {
  document: Document;
  trackingIdentifiers: TrackingIdentifiers;
  // A function that takes a Node and renders it (ie: into a React or DOM element)
  renderNode: (component: StyleguideComponent, props: StyleguideProps) => any;
  renderTutorial: (props: TutorialProps) => any;
  renderPortal: (props: PortalProps) => any;
  provider: CanduProviderContextType;
  stateProviderInstances: StateProviderInstances;
};

const getChildren = (node: EditorNode, renderNodeById) => {
  const props = node.props || {};
  const childNodes = node.nodes;
  const childrenProp = props.children;

  if (childrenProp) {
    return childrenProp;
  }

  if (childNodes) {
    return childNodes.map(renderNodeById);
  }

  return null;
};

const pickStateInstances = ({ states }: EditorNode, options: Options) =>
  states && options.stateProviderInstances.filter((instance) => !!states[instance.providerId]);

const getChildrenStateInstances = ({ nodes }: EditorNode, options: Options) =>
  nodes &&
  nodes
    .map((nodeId) => options.document.nodes[nodeId])
    .filter((node) => !!node)
    // TODO: replace with flatMap when we're ready
    .map((node) => pickStateInstances(node, options) || [])
    .reduce((acc, val) => acc.concat(val), []);

export const mapNodeToStyleguide = (nodeId: string, options: Options) => {
  try {
    const { provider, document, trackingIdentifiers } = options;

    const node = document.nodes[nodeId];

    // sanity check, this should never happen...
    if (!node) {
      logger.error('Document is corrupted. Refusing to move further.');
      return null;
    }

    const renderNodeById = (nodeIdToRender: string) => mapNodeToStyleguide(nodeIdToRender, options);

    const { type, props, css } = node;

    const attributes: StyleguideAttributes<any> = {
      ...(props || {}),
      style: css || {},
      // some nodes (Badge, Button for example) have text children properties.
      // otherwise, we compute the children by using the renderNode function
      children: getChildren(node, renderNodeById),
      ...getActionHandlers(node, {
        trackingIdentifiers,
        provider,
        document,
      }),
    };

    const api: DocumentApi = {
      nodeId,
      node,
      document,
      render: {
        node: renderNodeById,
        styleguide: (componentType, componentProps = {}) =>
          options.renderNode(componentType, { api, attributes: componentProps }),
        tutorial: (tutorialProps: TutorialProps) => options.renderTutorial(tutorialProps),
        portal: (portalProps: PortalProps) => options.renderPortal(portalProps),
      },
      trackingIdentifiers,
      stateInstances: pickStateInstances(node, options),
      childrenStateInstances: getChildrenStateInstances(node, options),
    };

    const renderedNode = api.render.styleguide(type, attributes);

    if (!renderedNode) {
      logger.error(ERROR_MESSAGES[EVENT_NAMES.UNKOWN_NODE_TYPE], { type });
      provider.eventing.error(new Error(ERROR_MESSAGES[EVENT_NAMES.UNKOWN_NODE_TYPE]), { type });
      return null;
    }

    return renderedNode;
  } catch (e) {
    logger.error(ERROR_MESSAGES[EVENT_NAMES.STYLEGUIDE_MAP_ERROR], e);
    options.provider.eventing.error(e);
    return null;
  }
};
