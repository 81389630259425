import { TutorialMetadata } from '../models';
import { TutorialPublishedStates } from './types';

export const getContentState = (tutorial: TutorialMetadata): TutorialPublishedStates => {
  if (!tutorial.lastModifiedAt) {
    return 'Draft';
  }

  if (tutorial.lastModifiedAt === tutorial.lastPublishedAt) {
    return 'Published';
  }

  if (tutorial.lastPublishedAt) {
    return 'Unsynced';
  }

  return 'Draft';
};
