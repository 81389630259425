import React, { CSSProperties, useState } from 'react';

import { CheckListItemStatus } from '@candulabs/core';

import { defaultStatusStyle } from './styles';

interface StatusInfo {
  title: string;
  statusStyle: CSSProperties;
  icon: {
    path: string;
    stroke: string;
  };
}

type StatusMap = {
  [key in CheckListItemStatus]: StatusInfo;
};

export const statusMap: StatusMap = {
  [CheckListItemStatus.NotStared]: {
    title: 'Not Started',
    statusStyle: {
      backgroundColor: '#EDEFF3',
      borderColor: '#D4D8E4',
    },
    icon: {
      path: 'M 2 6.55 L 5 6.55 L 10 6.55',
      stroke: '#9498A7',
    },
  },
  [CheckListItemStatus.InProgress]: {
    title: 'In Progress',
    statusStyle: {
      backgroundColor: '#FE8A3A',
      borderColor: 'white',
    },
    icon: {
      path: 'M 2.2808 6.5114 L 5.1884 9.6219 L 10 4.1',
      stroke: 'white',
    },
  },
  [CheckListItemStatus.Done]: {
    title: 'Completed',
    statusStyle: {
      backgroundColor: '#CFF5F5',
      borderColor: '#6DD8D8',
    },
    icon: {
      path: 'M 2.2808 6.5114 L 5.1884 9.6219 L 10 4.1',
      stroke: '#46AEAE',
    },
  },
};

interface Props {
  onChangeStatus: () => void;
  status: CheckListItemStatus;
}

export const StatusButton = ({ onChangeStatus, status }: Props) => {
  // TODO: get rid of statusHovered when we move to styleguide
  const [statusHovered, setStatusHovered] = useState(false);
  const statusStyle = {
    ...defaultStatusStyle,
    ...statusMap[status].statusStyle,
    border: `${statusHovered ? '1px' : '0px'} solid ${statusMap[status].statusStyle.borderColor}`,
  };
  const statusTitle = statusMap[status].title;
  const { icon } = statusMap[status];
  const onClick = (event: React.MouseEvent) => {
    onChangeStatus();
    event.stopPropagation();
  };
  return (
    <button
      type="button"
      onClick={onClick}
      style={statusStyle}
      title={statusTitle}
      onMouseOver={() => setStatusHovered(true)}
      onFocus={() => setStatusHovered(true)}
      onMouseOut={() => setStatusHovered(false)}
      onBlur={() => setStatusHovered(false)}
    >
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={icon.path}
          stroke={icon.stroke}
          strokeWidth="2.5"
          strokeLinecap="round"
          style={{
            transition: 'all .1s ease-out 0s',
          }}
        />
      </svg>
    </button>
  );
};
