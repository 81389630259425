const eventPrefix = (name: string) => `candu.sdk.${name}`;
const errorPrefix = (name: string) => eventPrefix(`error.${name}`);

export const ERROR_SLUGS = {
  UNKOWN_NODE_TYPE: 'unknown_node_type',

  UNKOWN_BLOCK_NODE_TYPE: 'unkown_block_node_type',

  UNKOWN_INLINE_NODE_TYPE: 'unkown_inline_node_type',

  UNKOWN_NODE_OBJECT_TYPE: 'unkown_node_object_type',
};

export const EVENT_NAMES = {
  /**
   * Preview is being called and we just opened
   */
  PREVIEW_OPEN: eventPrefix('preview.open'),

  /**
   * A portal is being shown
   */
  PORTAL: eventPrefix('portal'),

  /**
   * A content is being shown
   */
  CONTENT: eventPrefix('content'),

  /**
   * A tutorial is being shown
   */
  TUTORIAL: eventPrefix('tutorial'),

  /**
   * Could not find the portal asset
   */
  PORTAL_ASSET_NOT_FOUND: errorPrefix('portal_asset_not_found'),

  /**
   * Portal empty Content. We cannot render the portal because the
   * segment has no mappings in it.
   */
  PORTAL_NO_SEGMENT_IDS: errorPrefix('portal_no_segment_ids'),

  NO_MATCHING_TUTORIALS: errorPrefix('no_matching_tutorials'),

  /**
   * EmbedPortal with circular dependency.
   */
  CIRCULAR_DEPENDENCY_EMBED_PORTAL: errorPrefix('circular_dependecy_embed_portal'),

  /**
   * EmbedTutorial with circular dependency.
   */
  CIRCULAR_DEPENDENCY_EMBED_TUTORIAL: errorPrefix('circular_dependecy_embed_tutorial'),
};
