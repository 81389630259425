import React, { useEffect } from 'react';
import invariant from 'invariant';
import isObject from 'lodash/isObject';
import {
  isIdentifier,
  PortalSlug,
  TutorialDocument,
  METRICS,
  RESOURCES,
  ApiError,
} from '@candulabs/core';

import { useCanduContext } from '../CanduProviderContext';
import { EVENT_NAMES, ERROR_MESSAGES } from '../../constants';
import { PortalAsset, Styleguide } from '../../types';
import { useEventing, useFetch } from '../../hooks';
import { logger } from '../../utils';
import { useHasSegmentMembershipLoaded, useSegmentMembershipIds } from './hooks';
import { useStyleguide } from '../Styleguide/useStyleguide';
import { TutorialRouter } from './TutorialRouter';
import { TutorialFromPortal } from './TutorialFromPortal';

interface Props {
  /** Render a `Portal` by providing a `slug`. */
  slug: PortalSlug;

  /** Optional. Override the `Styleguide` for this `Portal`. */
  styleguide?: Partial<Styleguide>;
}

export const Portal = ({ slug, styleguide }: Props) => {
  invariant(isIdentifier(slug), '(Portal) You must supply a valid slug');
  invariant(
    !styleguide || isObject(styleguide),
    '(CanduProviders) styleguide must be undefined or an object',
  );

  const hasSegmentMembershipLoaded = useHasSegmentMembershipLoaded();
  if (!hasSegmentMembershipLoaded) {
    return null;
  }
  return <PortalLoader slug={slug} styleguide={styleguide} />;
};

export const PortalLoader = ({ slug, styleguide: customStyleguide }: Props) => {
  const segmentIds = useSegmentMembershipIds();
  const { clientToken, preview } = useCanduContext();
  const response = useFetch<PortalAsset | TutorialDocument | ApiError | null>(
    () => RESOURCES.portalAsset(clientToken, slug, segmentIds),
    [slug],
  );
  const { error, result, loadTime } = response;

  const styleguide = useStyleguide(customStyleguide).all();
  const { screen, track } = useEventing();

  const portalOrTutorial = !error && result;

  useEffect(() => {
    if (!preview?.client) {
      screen(EVENT_NAMES.PORTAL, { slug });
    }
  }, []);

  useEffect(() => {
    if (!error) {
      return;
    }

    // TODO: Add sentry
    logger.error(ERROR_MESSAGES[EVENT_NAMES.PORTAL_ASSET_NOT_FOUND]);
    track(EVENT_NAMES.PORTAL_ASSET_NOT_FOUND, { slug });
  }, [error]);

  useEffect(() => {
    if (loadTime) {
      track(METRICS.PORTAL_LOAD_TIME, { value: loadTime });
    }
  }, [loadTime]);

  // don't show or track any portal issues while we're still loading the segment membership.
  if (!portalOrTutorial) {
    return null;
  }

  if ((portalOrTutorial as TutorialDocument).document) {
    return (
      <TutorialFromPortal document={portalOrTutorial as TutorialDocument} styleguide={styleguide} />
    );
  }

  if ((portalOrTutorial as PortalAsset).content) {
    return <TutorialRouter portalAsset={portalOrTutorial as PortalAsset} styleguide={styleguide} />;
  }

  // TODO: add tracking
  return null;
};
