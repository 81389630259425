import { CheckListItemStatus } from '../models';
import { CheckListResolverInstance, StateProviderInstance, StateProviderInstances } from '../state';

interface ItemState {
  status: CheckListItemStatus;
  position: number;
}

export type ItemStates = Record<string, ItemState>;

export const getFirstNotStartedItem = (items: ItemStates, totalItems: number) => {
  const values = Object.values(items);
  for (let i = 0; i < totalItems; i += 1) {
    const foundItem = values.find((item) => item.position === i);
    if (!foundItem || foundItem.status === CheckListItemStatus.NotStared) {
      return i;
    }
  }
  return undefined;
};

export const calculateGroupCompletion = (items: ItemStates, totalItems: number) => {
  const completedItems = Object.values(items).filter(
    (item) => item.status === CheckListItemStatus.Done,
  ).length;
  const percComplete = totalItems ? Math.round((completedItems * 100) / totalItems) : 0;
  return { completedItems, percComplete };
};

export const getCheckListStateProviders = (
  stateInstances: StateProviderInstances,
): StateProviderInstance<CheckListResolverInstance>[] => {
  return stateInstances.filter(
    (instance) => instance.resolver instanceof CheckListResolverInstance,
  );
};

/**
 * Get initial items states, this is done synchronously to prevent flickering
 */
export const getInitialItems = (
  providers: StateProviderInstance<CheckListResolverInstance>[],
): ItemStates => {
  const items: ItemStates = {};
  providers.forEach((provider, position) => {
    const resolvedState = provider.getResolvedState();
    if (resolvedState) {
      items[resolvedState.id] = {
        status: resolvedState.state,
        position,
      };
    }
  });
  return items;
};
