import { useCanduContext } from '../CanduProviderContext';

export const useSegmentMembershipIds = () => {
  const { result } = useCanduContext().segmentMembership;
  return (result && result.segmentIds) || [];
};

export const useHasSegmentMembershipLoaded = () => {
  const { result } = useCanduContext().segmentMembership;
  return !!result;
};
