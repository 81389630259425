import { UserId } from './Input';

export interface Page {
  path: string;
  url: string;
  title: string;
  search?: string;
  referrer?: string;
}

export interface PackageInfo {
  name: string;
  version: string;
}

export interface Screen {
  height: number;
  width: number;
}

export enum Source {
  UserGenerated = 'UserGenerated',
  SDK = 'SDK',
}

export interface OperativeSystem {
  name: string;
  version: string;
}

export interface Context {
  sdk?: PackageInfo;
  library: PackageInfo;
  source?: Source;
  page?: Page;
  screen?: Screen;
  userAgent?: string;
  timezone?: string;
  ip?: string;
  locale?: string;
  os?: OperativeSystem;
}

export type ContextAdapter = (context: Context) => Context;

export interface Event {
  id: string;
  anonymousId: string;
  userId: UserId;
  type: EventType;
  context: Context;
  sentAt: string;
  groupId?: string;
  eventName?: string;
  pageName?: string;
  screenName?: string;
  properties?: object;
  traits?: object;
}

export interface EventOptions {
  sdk?: PackageInfo;
  source?: Source;
  eventName?: string;
  pageName?: string;
  screenName?: string;
  properties?: object;
  traits?: object;
  library?: PackageInfo;
  contextAdapter?: ContextAdapter;
}

export enum EventType {
  /** Given a user, identify it's properties */
  Identify = 'Identify',

  /** Identify a user as part of a group */
  Group = 'Group',

  /** Track a customer specified event */
  Track = 'Track',

  /** Track a customer specified page */
  Page = 'Page',

  /** Track a customer specified screen (e.g. Modal) */
  Screen = 'Screen',
}
