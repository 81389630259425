import React, { FunctionComponent } from 'react';
import { toCssSelector } from '@candulabs/core';

import { StyleguideProps } from '../../../types';

export const HtmlComponent = (Component): FunctionComponent<StyleguideProps> => ({
  attributes,
}: StyleguideProps) => {
  const isTypography = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'].includes(Component);
  const className = toCssSelector(isTypography ? 'typography' : Component, {
    variant: isTypography ? { variant: Component.toUpperCase() } : undefined,
    output: 'html',
  });

  return (
    <Component
      {...attributes}
      className={attributes.className ? `${attributes.className} ${className}` : className}
    />
  );
};
