import { StateCache } from './interface';

export class LocalStorageCache<T = any> implements StateCache<T> {
  private prefix: string;

  constructor(prefix = 'candu-state:') {
    this.prefix = prefix;
  }

  save(key: string, value: T) {
    try {
      window.localStorage.setItem(this.prefix + key, JSON.stringify(value));
    } catch (error) {
      // it's ok to ignore this error
    }
  }

  load(key: string): T | null {
    try {
      const item = window.localStorage.getItem(this.prefix + key);
      return item && JSON.parse(item);
    } catch (error) {
      return null;
    }
  }
}
