import {
  RESOURCES,
  SegmentMembershipPromise,
  EVENT_NAMES,
  ERROR_MESSAGES,
  ClientToken,
  UserId,
} from '@candulabs/core';

import { Eventing } from '@candulabs/eventing';
import isEqual from 'lodash/isEqual';

import { useFetch } from './useFetch';
import { Options } from '../types';
import useLocalStorage from './useLocalStorage';

const SHORT_FETCH = 1000;
const LONG_FETCH = 5000;

const promiseWithTimeout = (
  promise: () => Promise<any>,
  timeoutMs: number,
  failureMessage?: string,
) => {
  let timeoutHandle: NodeJS.Timeout;
  const timeoutPromise = new Promise((resolve, reject) => {
    timeoutHandle = setTimeout(() => {
      reject(new Error(failureMessage));
    }, timeoutMs);
  });

  return Promise.race([promise(), timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};

interface Props {
  clientToken: ClientToken;
  userId: UserId;
  options?: Options;
  eventing?: Eventing;
}

export const useSegmentMembership = ({
  clientToken,
  userId,
  options,
  eventing,
}: Props): SegmentMembershipPromise => {
  const [cachedSegmentMembership, setCachedSegmentMembership] = useLocalStorage(
    'canduSegmentMembership',
    null,
  );
  const { overrideSegmentMemberships, skipLoading } = options || {};

  const segmentMembershipPromise = useFetch(() => {
    if (skipLoading) {
      return null;
    }

    if (overrideSegmentMemberships) {
      return { id: userId, segmentIds: overrideSegmentMemberships };
    }

    if (cachedSegmentMembership) {
      // fetch membership with 1s timeout
      return promiseWithTimeout(
        () => RESOURCES.segmentMembership(clientToken, userId),
        SHORT_FETCH,
      ).catch(() => {
        // fallback to cache if timed out
        if (eventing) {
          eventing.track(ERROR_MESSAGES[EVENT_NAMES.SEGMENT_MEMBERSHIP_TIMEOUT], {
            timeout: SHORT_FETCH,
            cachedSegment: cachedSegmentMembership,
          });
        }

        return cachedSegmentMembership;
      });
    }

    return promiseWithTimeout(
      () => RESOURCES.segmentMembership(clientToken, userId),
      LONG_FETCH,
    ).catch(() => {
      // long fetch timed out, fall back to everyone
      if (eventing) {
        eventing.track(ERROR_MESSAGES[EVENT_NAMES.SEGMENT_MEMBERSHIP_TIMEOUT], {
          timeout: LONG_FETCH,
          cachedSegment: null,
        });
      }
      return { id: userId, segmentIds: [] };
    });
  }, [userId]);

  if (segmentMembershipPromise.result) {
    if (!cachedSegmentMembership) {
      // no cache, save result
      setCachedSegmentMembership(segmentMembershipPromise.result);
    } else if (!isEqual(segmentMembershipPromise.result, cachedSegmentMembership)) {
      // cache diff, overwrite
      setCachedSegmentMembership(segmentMembershipPromise.result);
    }
  }

  return segmentMembershipPromise;
};
