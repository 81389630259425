import React from 'react';

export const TutorialIcon = () => {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" fill="none">
      <path
        d="M7.26842 12.9518C7.27948 12.9081 7.30214 12.8682 7.334 12.8363L16.7329 3.43745C16.8305 3.33982 16.9888 3.33982 17.0864 3.43745L19.1897 5.54068C19.2873 5.63831 19.2873 5.7966 19.1897 5.89423L9.79079 15.2931C9.75893 15.325 9.71905 15.3476 9.67537 15.3587L6.85915 16.0717C6.67556 16.1182 6.50896 15.9516 6.55544 15.768L7.26842 12.9518Z"
        strokeWidth="1.5"
        fill="none"
        stroke="#BFA6F4"
      />
      <path
        d="M9.58591 6.29388H4.2002C3.09563 6.29388 2.2002 7.18931 2.2002 8.29388V17.4939C2.2002 18.5985 3.09562 19.4939 4.20019 19.4939H13.4002C14.5048 19.4939 15.4002 18.5985 15.4002 17.4939V14.4653"
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke="#BFA6F4"
      />
    </svg>
  );
};
