import React from 'react';
import { CodeData } from '@candulabs/core';

import { StyleguideProps } from '../../../types';

const defaultStyle = {
  borderRadius: '4px',
  padding: '8px',
  backgroundColor: 'rgb(51, 55, 69)',
  color: 'rgb(255, 255, 255)',
  overflow: 'auto',
};

/**
 * Render a block of code.
 *
 * The default implementation of Code is very simple, as
 * we don't want to introduce third party libraries to do syntax highlighing in the react-sdk bundle.
 */
export const Code = ({
  attributes: { children, ...otherAttributes },
}: StyleguideProps<CodeData>) => {
  return (
    <div {...otherAttributes} style={{ ...defaultStyle, ...(otherAttributes.style || {}) }}>
      <pre style={{ color: 'rgb(255, 255, 255)' }}>{children}</pre>
    </div>
  );
};
