import React, { Fragment, FunctionComponent } from 'react';
import isArray from 'lodash/isArray';

import { StyleguideProps } from '../../../types';

export const Text: FunctionComponent<StyleguideProps> = ({ attributes, api }: StyleguideProps) => {
  const { nodeId, document } = api;
  const node = !!document && !!nodeId && document.nodes[nodeId];
  let content = <Fragment>{attributes.children}</Fragment>;
  if (!node) {
    return content;
  }

  const marks =
    node.data && node.data.custom && isArray(node.data.custom.marks) ? node.data.custom.marks : [];

  if (marks.length === 0) {
    return content;
  }

  content = marks.reduce((children, mark) => {
    return api.render.styleguide(mark, {
      ...attributes,
      children,
    });
  }, content);

  return content;
};
