import { TutorialDependencies } from './TutorialDependencies';
import { CanduTutorialDocumentContextType } from '../contexts';
import { TutorialId } from '../models';

export const createTutorialDocumentContext = (
  id: TutorialId,
  parentContext: CanduTutorialDocumentContextType | null,
): CanduTutorialDocumentContextType => {
  return {
    id,
    dependencies: parentContext ? parentContext.dependencies : new TutorialDependencies(id),
  };
};

export const checkCyclicAndAdd = (
  context: CanduTutorialDocumentContextType,
  tutorialId: TutorialId,
) => {
  return context.dependencies.add(context.id, tutorialId);
};
