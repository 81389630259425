import React, { useState } from 'react';
import { TutorialDocument } from '@candulabs/core';

import { Styleguide } from '../../types';
import { Tutorial } from '../Tutorial';
import { PortalContext } from '../PortalContext';
import { ErrorBoundary } from '../ErrorBoundary';
import { PreviewWrapper } from '../PreviewWrapper';

interface Props {
  document: TutorialDocument;
  styleguide?: Partial<Styleguide>;
}

export const TutorialFromPortal = ({ styleguide, document }: Props) => {
  const [previewTutorial, setPreviewTutorial] = useState<TutorialDocument | null>(null);
  return (
    <ErrorBoundary type="portal">
      <PreviewWrapper
        type="portal"
        portalSlug={document.portal?.slug}
        currentTutorialId={previewTutorial?.id ?? document.id}
        onSegmentChange={(tutorialDocument) => setPreviewTutorial(tutorialDocument)}
      >
        <PortalContext.Provider
          value={{
            portalId: document.portal?.id,
            slug: document.portal?.slug,
            segmentId: document.portal?.matchingSegmentId,
            styleguide,
          }}
        >
          <Tutorial tutorialDocument={previewTutorial || document} />
        </PortalContext.Provider>
      </PreviewWrapper>
    </ErrorBoundary>
  );
};
