import React from 'react';
import { logger, EVENT_NAMES, ERROR_MESSAGES } from '@candulabs/core';

import { CanduProviderContext } from '../CanduProviderContext';

interface State {
  hasError: boolean;
}

type BoundryType = 'tutorial' | 'portal' | 'provider';

interface Props {
  type: BoundryType;
}

function boundryTypeToEvent(boundryType: BoundryType) {
  switch (boundryType) {
    case 'portal':
      return EVENT_NAMES.PORTAL_ERROR;
    case 'tutorial':
      return EVENT_NAMES.TUTORIAL_ERROR;
    case 'provider':
    default:
      return EVENT_NAMES.PROVIDER_ERROR;
  }
}

export class ErrorBoundary extends React.Component<Props, State> {
  static contextType = CanduProviderContext;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const eventName = boundryTypeToEvent(this.props.type);
    logger.error(ERROR_MESSAGES[eventName], error, errorInfo);
    if (this.context?.eventing?.error) {
      this.context.eventing.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      // we can render fallback UI here
      return null;
    }

    return this.props.children;
  }
}
