/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useEffect, useState } from 'react';

import {
  EVENT_NAMES,
  StyleguideProps,
  getCheckListStateProviders,
  ItemStates,
  getInitialItems,
  calculateGroupCompletion,
  getFirstNotStartedItem,
  CheckListGroupData,
  toCssSelector,
} from '@candulabs/core';

import { useCanduContext } from '../../../CanduProviderContext';
import { groupStyle, progressBarContainerStyle, progressBarStyle } from './styles';

export const CheckListGroup = ({
  attributes: { children, title, style, className, onClick },
  api: {
    childrenStateInstances,
    trackingIdentifiers,
    node: { data },
  },
}: StyleguideProps<CheckListGroupData>) => {
  const { eventing } = useCanduContext();

  // Each item has a separate state provider
  const providers = getCheckListStateProviders(childrenStateInstances || []);
  const totalItems = providers.length;

  // Items state
  const [items, setItems] = useState<ItemStates>(getInitialItems(providers));

  // Subscribe to items updates
  useEffect(() => {
    const unsubscribers = providers.map((provider, position) =>
      provider.subscribe((updatedItem) => {
        // Update items state
        setItems((prevStatuses) => ({
          ...prevStatuses,
          [updatedItem.id]: {
            position,
            status: updatedItem.state,
          },
        }));
      }),
    );

    return () => {
      unsubscribers.map((unsubscribe) => unsubscribe());
    };
  }, providers);

  // Progress bar
  const { percComplete } = calculateGroupCompletion(items, totalItems);
  useEffect(() => {
    if (percComplete === 100) {
      eventing.track(EVENT_NAMES.CHECKLIST_GROUP_COMPLETE, {
        title,
        groupId: data?.custom?.groupId,
        ...trackingIdentifiers,
      });
    }
  }, [percComplete]);

  // Find first not started item to expand it by default
  const firstNotStarted = getFirstNotStartedItem(items, totalItems);

  const titleClassName = toCssSelector('typography', {
    variant: { variant: 'H4' },
    output: 'html',
  });

  return (
    <div className={className} onClick={onClick} style={{ ...groupStyle, ...style }}>
      <h4 className={titleClassName}>{title}</h4>
      <div style={progressBarContainerStyle}>
        <div
          style={{
            ...progressBarStyle,
            width: `${percComplete}%`,
          }}
        />
      </div>
      {!!children &&
        React.Children.map(children, (child: ReactElement, index) =>
          React.cloneElement(child, { expandByDefault: index === firstNotStarted }),
        )}
    </div>
  );
};
