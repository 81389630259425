import isString from 'lodash/isString';

import { isIdentifier, IsNumericId } from '../utils';

import { PortalId, PortalSlug } from './Portal';
import { ContentHashId } from './Hashes';
import { TutorialId, TutorialSlug } from './TutorialDocument';

export const isContentHashId = (hash: any): hash is ContentHashId =>
  isString(hash) && !!hash.match(/^[0-9a-zA-Z]{40}$/);

export const isPortalId = (id: any): id is PortalId => IsNumericId(id);
export const isPortalSlug = (slug: any): slug is PortalSlug => isIdentifier(slug);

export const isTutorialId = (id): id is TutorialId => IsNumericId(id);
export const isTutorialSlug = (slug: any): slug is TutorialSlug => isIdentifier(slug);
