import { CSSProperties } from 'react';

const container: CSSProperties = {
  position: 'relative',
  background: 'transparent',
  color: '#fff',
  marginRight: '16px',
};

const header: CSSProperties = {
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
};

const icon: CSSProperties = {
  marginRight: '8px',
  display: 'flex',
  width: 22,
  height: 22,
};

const label: CSSProperties = {
  marginRight: '8px',
  marginBottom: '4px',
  fontSize: '18px',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const menu: CSSProperties = {
  position: 'absolute',
  width: '200px',
  maxHeight: '120px',
  overflowY: 'auto',
  top: '100%',
  background: '#fff',
  borderRadius: '3px',
  marginTop: 0,
  padding: 0,
  boxShadow: '0px 3px 17px -5px rgb(0 0 0 / 52%)',
  transition: 'all 0.2s ease',
};

const menuItem: CSSProperties = {
  margin: '0',
  fontSize: '14px',
  display: 'block',
  cursor: 'pointer',
  padding: '10px 15px',
  color: '#333745',
  transition: 'all 0.2s ease',
};

const hoverState: CSSProperties = {
  backgroundColor: '#EDEFF3',
};

const defaultState: CSSProperties = {
  backgroundColor: '#FFFFFF',
};

export const styles = {
  container,
  header,
  icon,
  label,
  menu,
  menuItem,
  hoverState,
  defaultState,
};
