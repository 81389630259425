import { Eventing } from '@candulabs/eventing';
import { UserId } from '../models';

import { TutorialProps } from '../inputValidation';

import { EVENT_NAMES } from './eventNames';
import { trackStyleguideProperties } from './trackStyleguideProperties';
import { ERROR_MESSAGES } from './errorMessages';
import { LOGGER } from './logger';

export const internalEventing = ({ identify, track }: Eventing) => ({
  provider: ({
    userId,
    traits,
    styleguide,
  }: {
    userId: UserId;
    traits: Record<string, any>;
    styleguide: Record<string, any>;
  }) => {
    identify(userId, traits);
    track(EVENT_NAMES.HEARTBEAT, trackStyleguideProperties(styleguide));
  },

  tutorialDocumentLoadingError: ({ tutorialId, slug, contentHashId }: TutorialProps) => {
    LOGGER.error(ERROR_MESSAGES[EVENT_NAMES.TUTORIAL_LOADING_ERROR]);
    track(EVENT_NAMES.TUTORIAL_LOADING_ERROR, {
      tutorialId,
      slug,
      contentHashId,
    });
  },
});
