import React, { FunctionComponent } from 'react';

import { View } from './View';
import { StyleguideProps } from '../../../types';

/**
 * A Document is the top root element and is the same as a `div` in HTML.
 *
 * We don't recommend overriding this elemenent in your Styleguide
 * unless you have a specific use-case.
 */
export const Document: FunctionComponent<StyleguideProps> = ({ attributes, ...otherProps }) => {
  const { style } = attributes;

  const overflowStyle = {
    overflow: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
  };

  return (
    <View
      {...otherProps}
      attributes={{
        ...attributes,
        style: { ...overflowStyle, ...style },
        className: 'candu-document',
      }}
    />
  );
};
