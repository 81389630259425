import { CSSProperties } from 'react';

import { CDN_BASE_URL } from '../networking/constants';

const getStylesheetId = (clientToken: string) => `candu-styleguide-${clientToken}`;

export const attachStylesheet = (clientToken: string, stylesheetName = 'main') => {
  const id = getStylesheetId(clientToken);
  if (document.getElementById(id)) {
    return;
  }
  const link = document.createElement('link');
  link.setAttribute('id', id);
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute(
    'href',
    `${CDN_BASE_URL}/styleguides/applications/${clientToken}/default/${stylesheetName}.css`,
  );

  document.head.appendChild(link);
};

export const detachStylesheet = (clientToken: string) => {
  const link = document.getElementById(getStylesheetId(clientToken));

  if (link) {
    link.remove();
  }
};

const getParsedSpacing = (spacingType, spacing) => {
  const spacingArray = typeof spacing === 'string' && spacing.split(' ');
  if (!spacingArray) {
    return {};
  }

  if (spacingArray.length === 1) {
    return {
      [`${spacingType}Right`]: spacing,
      [`${spacingType}Left`]: spacing,
      [`${spacingType}Bottom`]: spacing,
      [`${spacingType}Top`]: spacing,
    };
  }

  if (spacingArray.length === 2) {
    return {
      [`${spacingType}Top`]: spacingArray[0],
      [`${spacingType}Right`]: spacingArray[1],
      [`${spacingType}Bottom`]: spacingArray[0],
      [`${spacingType}Left`]: spacingArray[1],
    };
  }

  if (spacingArray.length === 3) {
    return {
      [`${spacingType}Top`]: spacingArray[0],
      [`${spacingType}Right`]: spacingArray[1],
      [`${spacingType}Bottom`]: spacingArray[2],
      [`${spacingType}Left`]: spacingArray[1],
    };
  }

  return {
    [`${spacingType}Top`]: spacingArray[0],
    [`${spacingType}Right`]: spacingArray[1],
    [`${spacingType}Bottom`]: spacingArray[2],
    [`${spacingType}Left`]: spacingArray[3],
  };
};

/**
 * When applying style object to a Node, it is possible for both
 * shorthand and specific version of same property to be defined
 * eg. margin + marginTop, if we try to apply them at the same time
 * browsers will complain about possible styling inconsistencies
 * @param style
 */
export const unpackShorthandStyles = ({
  padding,
  margin,
  ...other
}: CSSProperties): CSSProperties => {
  let output = {};
  if (padding) {
    output = {
      ...output,
      ...getParsedSpacing('padding', padding),
    };
  }

  if (margin) {
    output = {
      ...output,
      ...getParsedSpacing('margin', margin),
    };
  }
  return { ...output, ...other };
};
