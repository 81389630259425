import React from 'react';

import { StyleguideProps } from '../../../types';

const flexDefaultStyle = {
  display: 'flex',
};

/**
 * Flex Container that defines layout of children under.
 *
 * We don't recommend overriding this elemenent in your Styleguide
 * unless you have a specific use-case.
 */
export const Flex = ({ attributes }: StyleguideProps) => (
  <div {...attributes} style={{ ...flexDefaultStyle, ...attributes.style }} />
);

const flexItemDefaultStyle = {
  flex: '1 1',
};

/**
 * FlexItem flex child -> can position iteslf within container
 *
 * We don't recommend overriding this elemenent in your Styleguide
 * unless you have a specific use-case.
 */
export const FlexItem = ({ attributes }: StyleguideProps) => (
  <div {...attributes} style={{ ...flexItemDefaultStyle, ...attributes.style }} />
);
