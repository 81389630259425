import React, { useEffect } from 'react';
import invariant from 'invariant';
import { isApiError, isIdentifier, METRICS, RESOURCES } from '@candulabs/core';

import { useCanduContext } from '../CanduProviderContext';
import { EVENT_NAMES } from '../../constants';
import { useEventing, useFetch } from '../../hooks';
import { useHasSegmentMembershipLoaded, useSegmentMembershipIds } from './hooks';
import { TutorialDocument } from '../TutorialDocument';

interface Props {
  slug: string;
}

export const Content = ({ slug }: Props) => {
  invariant(isIdentifier(slug), '(Content) You must supply a valid slug');

  const hasSegmentMembershipLoaded = useHasSegmentMembershipLoaded();
  if (!hasSegmentMembershipLoaded) {
    return null;
  }
  return <ContentLoader slug={slug} />;
};

export const ContentLoader = ({ slug }: Props) => {
  const segmentIds = useSegmentMembershipIds();
  const { clientToken } = useCanduContext();
  const response = useFetch(() => RESOURCES.contentDocument(clientToken, slug, segmentIds), [slug]);
  const { result: contentDocument, loadTime } = response;

  const { screen, track } = useEventing();

  useEffect(() => {
    screen(EVENT_NAMES.CONTENT, { slug });
  }, []);

  // TODO: add error reporting here or do it in core?

  useEffect(() => {
    if (loadTime) {
      track(METRICS.CONTENT_LOAD_TIME, { value: loadTime });
    }
  }, [loadTime]);

  // TODO: add better error handling
  if (!contentDocument || isApiError(contentDocument)) {
    return null;
  }

  return <TutorialDocument contentType="Content" slug={slug} tutorialDocument={contentDocument} />;
};
