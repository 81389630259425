const eventPrefix = (name: string) => `candu.sdk.${name}`;
const errorPrefix = (name: string) => eventPrefix(`error.${name}`);

export const ERROR_SLUGS = {
  UNKOWN_NODE_TYPE: 'unknown_node_type',

  UNKOWN_BLOCK_NODE_TYPE: 'unkown_block_node_type',

  UNKOWN_INLINE_NODE_TYPE: 'unkown_inline_node_type',

  UNKOWN_NODE_OBJECT_TYPE: 'unkown_node_object_type',

  TUTORIAL_ERROR: 'tutorial_error_boundary',

  PORTAL_ERROR: 'portal_error_boundary',

  PROVIDER_ERROR: 'provider_error_boundary',

  STYLEGUIDE_MAP_ERROR: 'styleguide_map',

  RENDER_NODE_ERROR: 'render_node',
};

export const EVENT_NAMES = {
  /**
   * A new portal has been defined
   */
  HEARTBEAT: eventPrefix('heartbeat'),
  /**
   * Preview is being called and we just opened
   */
  PREVIEW_OPEN: eventPrefix('preview.open'),
  /**
   * A portal is being shown
   */
  PORTAL: eventPrefix('portal'),

  /**
   * A tutorial is being shown
   */
  TUTORIAL: eventPrefix('tutorial'),

  /**
   * An interaction in a tutorial
   */
  TUTORIAL_INTERACTION: eventPrefix('tutorial.interaction'),

  /**
   * Could not find the portal asset
   */
  PORTAL_ASSET_NOT_FOUND: errorPrefix('portal_asset_not_found'),

  /**
   * Portal empty Content. We cannot render the portal because the
   * segment has no mappings in it.
   */
  PORTAL_NO_SEGMENT_IDS: errorPrefix('portal_no_segment_ids'),

  NO_MATCHING_TUTORIALS: errorPrefix('no_matching_tutorials'),

  NO_VALID_TUTORIAL_IDENTIFIER: errorPrefix('no_valid_tutorial_identifier'),

  COMMIT_API_LOADING_ERROR: errorPrefix('commit_api'),

  TUTORIAL_LOADING_ERROR: errorPrefix('tutorial'),

  UNKOWN_NODE_TYPE: errorPrefix(ERROR_SLUGS.UNKOWN_NODE_TYPE),

  UNKOWN_INLINE_NODE_TYPE: errorPrefix(ERROR_SLUGS.UNKOWN_INLINE_NODE_TYPE),

  UNKOWN_NODE_OBJECT_TYPE: errorPrefix(ERROR_SLUGS.UNKOWN_NODE_OBJECT_TYPE),

  TUTORIAL_ERROR: errorPrefix(ERROR_SLUGS.TUTORIAL_ERROR),

  PORTAL_ERROR: errorPrefix(ERROR_SLUGS.PORTAL_ERROR),

  PROVIDER_ERROR: errorPrefix(ERROR_SLUGS.PROVIDER_ERROR),

  STYLEGUIDE_MAP_ERROR: errorPrefix(ERROR_SLUGS.TUTORIAL_ERROR),

  RENDER_NODE_ERROR: errorPrefix(ERROR_SLUGS.TUTORIAL_ERROR),

  /**
   * EmbedPortal with circular dependency.
   */
  CIRCULAR_DEPENDENCY_EMBED_PORTAL: errorPrefix('circular_dependecy_embed_portal'),

  /**
   * EmbedTutorial with circular dependency.
   */
  CIRCULAR_DEPENDENCY_EMBED_TUTORIAL: errorPrefix('circular_dependecy_embed_tutorial'),

  STATE_FETCH_FAILED: errorPrefix('state_fetch_failed'),

  STATE_UPDATE_FAILED: errorPrefix('state_update_failed'),

  SEGMENT_MEMBERSHIP_TIMEOUT: errorPrefix('segment_membership_timeout'),

  CHECKLIST_ITEM_STATE_UPDATED: eventPrefix('tutorial.checklist_item_state_updated'),

  CHECKLIST_GROUP_COMPLETE: eventPrefix('tutorial.checklist_group_complete'),

  CARD_DISMISSED: eventPrefix('tutorial.card_dismissed'),
};

const metricPrefix = (name: string) => eventPrefix(`metric.${name}`);

export const METRICS = {
  SEGMENT_MEMBERSHIP_LOAD_TIME: metricPrefix('segment_membership_load_time'),
  PORTAL_LOAD_TIME: metricPrefix('portal_load_time'),
  CONTENT_LOAD_TIME: metricPrefix('content_load_time'),
  TUTORIAL_LOAD_TIME: metricPrefix('tutorial_load_time'),
  TUTORIAL_DOCUMENT_MOUNT_TIME: metricPrefix('tutorial_document_mount_time'),
};
