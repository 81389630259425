import React, { useEffect, useState, useContext } from 'react';
import { findMatchingSegment, TutorialDocument } from '@candulabs/core';

import { EVENT_NAMES, ERROR_MESSAGES } from '../../constants';
import { useEventing } from '../../hooks';
import { logger } from '../../utils';
import { PortalAsset, Styleguide } from '../../types';
import { Tutorial } from '../Tutorial';
import { PortalContext } from '../PortalContext';
import { PreviewWrapper } from '../PreviewWrapper';
import { ErrorBoundary } from '../ErrorBoundary';
import { useSegmentMembershipIds } from './hooks';
import { PreviewContext } from '../PreviewWrapper/PreviewContext';

interface Props {
  portalAsset: PortalAsset;
  styleguide?: Partial<Styleguide>;
}

export const TutorialRouter = ({ styleguide, portalAsset }: Props) => {
  const segmentIds = useSegmentMembershipIds();
  const { track } = useEventing();
  const previewContext = useContext(PreviewContext);
  const [previewTutorial, setPreviewTutorial] = useState<TutorialDocument | null>(null);
  const [matchingSegment, setMatchingSegment] = useState(
    findMatchingSegment(portalAsset, segmentIds),
  );

  useEffect(() => {
    if (portalAsset.content.length > 0) {
      return;
    }

    // TODO: Add sentry
    logger.warning(ERROR_MESSAGES[EVENT_NAMES.NO_MATCHING_TUTORIALS]);
    track(EVENT_NAMES.PORTAL_NO_SEGMENT_IDS, {
      portalId: portalAsset.id,
      slug: portalAsset.slug,
    });
  }, [portalAsset]);

  useEffect(() => {
    const previewSegmentId = previewContext.segmentId;
    if (previewSegmentId) {
      const previewMatchingSegment = findMatchingSegment(portalAsset, [previewSegmentId]);
      setMatchingSegment(previewMatchingSegment);
    }
  }, [previewContext.segmentId]);

  if (!matchingSegment) {
    return null;
  }

  return (
    <ErrorBoundary type="portal">
      <PreviewWrapper
        type="portal"
        portalSlug={portalAsset.slug}
        currentTutorialId={previewTutorial?.id ?? matchingSegment.tutorialId}
        onSegmentChange={(tutorialDocument) => setPreviewTutorial(tutorialDocument)}
      >
        <PortalContext.Provider
          value={{
            portalId: portalAsset.id,
            slug: portalAsset.slug,
            segmentId: matchingSegment.segmentId,
            styleguide,
          }}
        >
          <Tutorial
            contentHashId={matchingSegment.tutorialVersionId}
            tutorialDocument={previewTutorial || undefined}
          />
        </PortalContext.Provider>
      </PreviewWrapper>
    </ErrorBoundary>
  );
};
